<template>   
    <v-card
        rounded="lg">
        <v-row class="bg-blue107 align-center py-4 px-5" no-gutters>
            <span class="py-2 fnt1-2 font-weight-bold">{{ props.psdObj.mjrPrdCdNm }} &#47; {{ props.psdObj.prdCdNm }} 결제 내역</span>
            <v-spacer></v-spacer>
            <v-btn
                class=""
                @click="onClose"
                variant="plain" size="30" icon slim>
                <v-icon color="blue102" size="26">mdi-window-close</v-icon>
            </v-btn>
        </v-row>
        <v-divider></v-divider>   
        <v-row class="px-3 mt-3" no-gutters>                
            <v-col class="bg-blue107 py-2 ma-0" cols="4">
                <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">결제일자</span>
            </v-col>
            <v-col class="bg-blue107 py-2  ma-0" cols="4">
                <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">상품명</span>
            </v-col>
            <v-col class="bg-blue107 py-2 ma-0" cols="4">
                <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">이용기간</span>
            </v-col>
        </v-row>
        <v-row class="px-3 mt-1" no-gutters>                
            <v-col class="pl-2 ma-0" cols="4">
                {{   $moment(props.psdObj.pymtDt, 'YYYYMMDD').format('YYYY.MM.DD')}}
            </v-col>
            <v-col class="pl-2 ma-0" cols="4">
                {{ props.psdObj.mjrPrdCdNm }} &#47; {{ props.psdObj.prdCdNm }}
            </v-col>
            <v-col class="pl-2 ma-0" cols="4">
                <span v-if="props.psdObj.srvcClcd == '2'">{{props.psdObj.txtCnt?.toLocaleString('ko-KR')}}</span>
                <span v-else>{{props.psdObj.pymtMnth}} 개월</span>
            </v-col>
        </v-row>  
        <v-row v-if="props.psdObj.cnclYn == '1'" class="px-3 mt-3" no-gutters>                
            <v-col class="bg-blue107 py-2 ma-0" cols="4">
                <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">상태</span>
            </v-col>
            <v-col class="bg-blue107 py-2  ma-0" cols="4">
                <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">취소금액</span>
            </v-col>
            <v-col class="bg-blue107 py-2 ma-0" cols="4">
                <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">취소기간</span>
            </v-col>
        </v-row>  
        <v-row  v-if="props.psdObj.cnclYn == '1'" class="px-3 mt-1" no-gutters>                
            <v-col class="pl-2 ma-0" cols="4">
                취소
            </v-col>
            <v-col class="pl-2 ma-0" cols="4">
                {{ props.psdObj.cnclAmt?.toLocaleString('ko-KR') }} &#40; 부가세 &#58; {{ props.psdObj.cnclTax }} &#41;
            </v-col>
            <v-col class="pl-2 ma-0" cols="4">
                <span v-if="props.psdObj.srvcClcd == '2'">{{props.psdObj.cnclTxtCnt?.toLocaleString('ko-KR')}}</span>
                <span v-else>{{props.psdObj.cnclMnth}} 개월</span>
            </v-col>
        </v-row>
        <v-row class="py-4 px-5" no-gutters>

        </v-row>          
    </v-card> 
</template>
<script setup>
    import { onMounted, getCurrentInstance} from 'vue'
    import { glbDlg, notiInf, lgnInf} from "@/state"
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    const props     = defineProps(['psdObj']) 
    const dlgInf    = glbDlg()
    const noti      = notiInf()
    const lgn       = lgnInf()
    const router    = useRouter()   
    const emit      = defineEmits(['isClose'])
    const $moment   = getCurrentInstance().appContext.config.globalProperties.$moment

    onMounted(() => {
        console.log(props.psdObj)
        getYysPymHisInfo()
    })

    function onClose() {
        console.log('close')
        emit('isClose')
    }

    async function getYysPymHisInfo() {

        let obj = {
            fcltyNum : props.psdObj.fcltyNum,
            yysPymHisPk : props.psdObj.yysPymHisPk,
        }
        
        await cmsApi.post('yyspym/selYysPymHisInfo', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    
                    console.log(response.data)
                }
                else{
                    dlgInf.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }
</script>
