<template>   
    <v-row no-gutters>
        <v-col cols="12">
            <v-tabs
                v-model="tabVal"
                class="vtab-theme-default mb-2" selected-class="vtab-theme-selected" color="white" height="40"
                align-tabs="start"
                hide-slider>
                <v-tab v-for="(item, i) in tabItem" :key="i" :value="item.value" class="text-subtitle-2 font-weight-bold">{{item.title}}</v-tab>
            </v-tabs>               
        </v-col>
    </v-row>
    <div v-if="useLstList.length > 0">
        <v-row v-for="(list, j) in useLstList" :key="j" class="srndBrdr-grey004 bg-white rounded py-2 px-4" no-gutters>
            <v-col v-if="tabVal == list.srvcClcd" cols="12">
                <v-row v-if="list.srvcClcd == '2'" no-gutters>
                    <v-col cols="2">문자건수</v-col>
                    <v-col cols="4">{{ list.txtCnt }}</v-col>
                    <v-col cols="2">차감문자건수</v-col>
                    <v-col cols="4">{{ list.ddcTxtCnt }}</v-col>
                </v-row>
                <v-row v-else no-gutters>                
                    <v-col cols="2">이용시작일자</v-col>
                    <v-col cols="4">{{ $moment(list.useDtStrt, 'YYYYMMDD').format('YYYY.MM.DD') }}</v-col>
                    <v-col cols="2">이용종료일자</v-col>
                    <v-col cols="4">{{ $moment(list.useDtEnd, 'YYYYMMDD').format('YYYY.MM.DD') }}</v-col>
                </v-row>
                
            </v-col>
            <v-col v-else cols="12">
                이용내역이 없습니다.
            </v-col>
        </v-row>
    </div>
    <div v-else>
        <v-row class="srndBrdr-grey004 bg-white rounded py-2 px-4" no-gutters>
            <v-col cols="12">
                이용내역이 없습니다.
            </v-col>
        </v-row>
    </div>

    <v-row class="mt-3" no-gutters>
        <v-data-table-server
            class="tbl-theme rounded" density="compact" color="red"
            :height="dsply.height - 436"    
            v-model:items-per-page="yysUseParams.rnLmt"                
            :headers="(yysUseParams.srvcClcd == '2')?tblUse.headers2:tblUse.headers"
            :items-length="tblUse.totalItems"
            :items="tblUse.serverItems"
            :loading="tblUse.loading"
            @update:options="getYysUseList"
            fixed-header expand-on-click>
            <template v-slot:item="{ item }">
                <tr @click="tblFcltyPymInfAct(item)">
                    <td class="txtAlgnMid">{{$moment(item.pymtDt, 'YYYYMMDD').format('YYYY.MM.DD')}}</td>
                    <td class="txtAlgnMid">{{item.mjrPrdCdNm}}</td>
                    <td class="txtAlgnMid">
                        <span>{{item.prdCdNm}}</span>
                        <v-icon class="pl-2" v-if="item.prdTypCd == '2'" color="blue" size="13">mdi-gift-open-outline</v-icon>
                    </td>
                    <td class="txtAlgnMid">{{item.bnfcrCnt}}</td>
                    <td class="txtAlgnMid">
                        <span v-if="yysUseParams.srvcClcd == '2'">{{item.txtCnt?.toLocaleString('ko-KR')}}</span>
                        <span v-else>{{item.pymtMnth}} 개월</span>
                    </td>
                    <td class="txtAlgnMid">{{item.useAmt?.toLocaleString('ko-KR')}}</td>
                    <td class="txtAlgnMid">{{item.vat?.toLocaleString('ko-KR')}}</td>
                    <td class="txtAlgnMid">                        
                        <v-row v-if="item.cnclYn == '1'" class="fnt0-6" no-gutters>
                            <v-col cols="4" class="txtAlgnFst pa-0 ma-0">상태  : </v-col>
                            <v-col cols="8" class="pa-0 ma-0">취소</v-col>
                        </v-row>
                        <v-row v-if="item.cnclYn == '1'" class="fnt0-6" no-gutters>
                            <v-col cols="4" class="txtAlgnFst pa-0 ma-0">금액  : </v-col>
                            <v-col cols="8" class="pa-0 ma-0">{{ item.cnclAmt?.toLocaleString('ko-KR') }}</v-col>
                        </v-row>
                        <v-row v-if="item.cnclYn == '1'" class="fnt0-6" no-gutters>
                            <v-col cols="4" class="txtAlgnFst pa-0 ma-0">부과세  : </v-col>
                            <v-col cols="8" class="pa-0 ma-0">{{ item.cnclTax?.toLocaleString('ko-KR') }}</v-col>
                        </v-row>
                        <v-row v-if="item.cnclYn == '1' && yysUseParams.srvcClcd == '2'" class="fnt0-6" no-gutters>
                            <v-col cols="4" class="txtAlgnFst pa-0 ma-0">문자건수  : </v-col>
                            <v-col cols="8" class="pa-0 ma-0">{{ item.cnclTxtCnt?.toLocaleString('ko-KR') }}</v-col>
                        </v-row>
                        <v-row v-if="item.cnclYn == '1' && yysUseParams.srvcClcd != '2'" class="fnt0-6" no-gutters>
                            <v-col cols="4" class="txtAlgnFst pa-0 ma-0">기간  : </v-col>
                            <v-col cols="8" class="pa-0 ma-0">{{ item.cnclMnth }} 개월</v-col>
                        </v-row>
                    </td>
                </tr>
            </template>
        </v-data-table-server>
    </v-row>
    <v-dialog v-model="pymHisDlg" width="1000"> 
        <FcltyPymHisInfMdl v-if="pymHisDlg" :psdObj="yysPymHisInf" @isClose="onPymHisDlgClose"></FcltyPymHisInfMdl>
    </v-dialog>
</template>
<script setup>
    import FcltyPymHisInfMdl from '@/components/yys/FcltyPymHisInfMdl.vue'        // 결제내역 관리

    import { reactive, onMounted, watch, ref, getCurrentInstance} from 'vue'
    import { glbDlg, notiInf, displayInfStore, lgnInf} from "@/state"
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    const props     = defineProps(['psdObj'])    
    const dlgInf = glbDlg()
    const noti      = notiInf()
    const lgn       = lgnInf()
    const router    = useRouter()
    let tabVal      = ref(null)
    let pymHisDlg   = ref(false)
    const $moment   = getCurrentInstance().appContext.config.globalProperties.$moment

    let dsply = reactive({
        width: 0,
        height: 0,
    })

    let fcltyInf = reactive({            
        fcltyClcdNm : '',
        fcltyNum    : '',
    })
    
    let yysUseParams = reactive({
        fcltyNum    : '',
        srvcClcd    : '',
        yysUseLstPk : 0,
        rnStrt      : 0,
        rnLmt       : 10,  
    })

    let yysPymHisInf = reactive({                    
        fcltyNum    : '',
        bnfcrCnt    : 0,
        cnclAmt     : 0,
        cnclMnth    : 0,
        cnclTax     : 0,
        cnclTxtCnt  : 0,
        cnclYn      : '',
        mjrPrdCd    : '',
        mjrPrdCdNm  : '',
        prdCd       : '',
        prdCdNm     : '',
        prdTypCd    : '',
        pymtDt      : '',
        pymtMnth    : 0,
        srvcClcd    : '',
        txtCnt      : 0,
        useAmt      : 0,
        vat         : 0,
        yysPymHisPk : 0,
        yysUseLstPk : 0,
        yysUsePk    : 0,
    })

    let tabItem = reactive([
        {id:'sys',title:'요양시스', value:1},
        {id:'sms',title:'문자충전', value:2},
        {id:'hom',title:'홈페이지', value:3},
    ])

    let prdctList = reactive([{
            yysPrdctPk  : 0,
            srvcClcd    : '',
            prdCd       : '',
            prdNm       : '',
            prdTypCd    : '',
            useMnth     : '',
            useTxtCnt   : 0,
            prdDesc     : '',
            listDtl:[]
    }])

    let useLstList = reactive([{
        yysUseLstPk:0,
        srvcClcd:'',
        useDtStrt:'',
        useDtEnd:'',
        txtCnt:0,
        ddcTxtCnt:0,
        listDtl:[]
    }])

    let tblUse = reactive({
        loading: false,
        totalItems: 0,
        serverItems: [],
        headers:[
            { title: '결제일자', key: 'pymtDt', sortable: false, align: 'center', width: '100' },
            { title: '대표상품', key: 'mjrPrdCdNm', sortable: false, align: 'center', width: '120' },
            { title: '상품', key: 'prdCdNm', sortable: false, align: 'center', minWidth: '120' },
            { title: '수급자수', key: 'bnfcrCnt', sortable: false, align: 'center', minWidth: '80' },            
            { title: '이용기간', key: 'pymtMnth', sortable: false, align: 'center', width: '80' },            
            { title: '이용요금', key: 'useAmt', sortable: false, align: 'center', width: '100' },
            { title: '부가세', key: 'vat', sortable: false, align: 'center', width: '100' },
            { title: '취소여부', key: 'cnclYn', sortable: false, align: 'center', width: '160' },
        ],  
        headers2:[
            { title: '결제일자', key: 'pymtDt', sortable: false, align: 'center', width: '100' },
            { title: '대표상품', key: 'mjrPrdCdNm', sortable: false, align: 'center', width: '120' },
            { title: '상품', key: 'prdCdNm', sortable: false, align: 'center', minWidth: '120' },
            { title: '수급자수', key: 'bnfcrCnt', sortable: false, align: 'center', minWidth: '80' },                        
            { title: '문자건수', key: 'txtCnt', sortable: false, align: 'center', width: '80' },
            { title: '이용요금', key: 'useAmt', sortable: false, align: 'center', width: '100' },
            { title: '부가세', key: 'vat', sortable: false, align: 'center', width: '100' },
            { title: '취소여부', key: 'cnclYn', sortable: false, align: 'center', width: '160' },
        ],         
    })


    onMounted(() => {
        setFcltyPymInf(props.psdObj)
    })

    watch(() => props.psdObj, (newValue) => {
        setFcltyPymInf(newValue)
    })

    watch(() => displayInfStore().width, (newValue) => {
        dsply.width = newValue
    })
    watch(() => displayInfStore().height, (newValue) => {
        dsply.height = newValue
    })

    watch(() => tabVal.value, (value) => {
        
        yysUseParams.srvcClcd       = tabVal.value

        if(value != null && Number(value) > 0){
            let idx = useLstList.findIndex(v=> v.srvcClcd == value)
            if(idx > -1){

                yysUseParams.yysUseLstPk    = useLstList[idx].yysUseLstPk 

                let obj = {}
                obj.page = 1
                obj.itemsPerPage = yysUseParams.rnLmt
                getYysUseList(obj)
            } else {
                tblUse.serverItems.splice(0)
            }
        }
    })

    function tblFcltyPymInfAct(params) {        
        yysPymHisInf.fcltyNum    = fcltyInf.fcltyNum
        yysPymHisInf.bnfcrCnt    = params.bnfcrCnt
        yysPymHisInf.cnclAmt     = params.cnclAmt
        yysPymHisInf.cnclMnth    = params.cnclMnth
        yysPymHisInf.cnclTax     = params.cnclTax
        yysPymHisInf.cnclTxtCnt  = params.cnclTxtCnt
        yysPymHisInf.cnclYn      = params.cnclYn
        yysPymHisInf.mjrPrdCd    = params.mjrPrdCd
        yysPymHisInf.mjrPrdCdNm  = params.mjrPrdCdNm
        yysPymHisInf.prdCd       = params.prdCd
        yysPymHisInf.prdCdNm     = params.prdCdNm
        yysPymHisInf.prdTypCd    = params.prdTypCd
        yysPymHisInf.pymtDt      = params.pymtDt
        yysPymHisInf.pymtMnth    = params.pymtMnth
        yysPymHisInf.srvcClcd    = params.srvcClcd
        yysPymHisInf.txtCnt      = params.txtCnt
        yysPymHisInf.useAmt      = params.useAmt
        yysPymHisInf.vat         = params.vat
        yysPymHisInf.yysPymHisPk = params.yysPymHisPk
        yysPymHisInf.yysUseLstPk = params.yysUseLstPk
        yysPymHisInf.yysUsePk    = params.yysUsePk

        pymHisDlg.value = true
    }

    function onPymHisDlgClose(){
        console.log('dd')
        pymHisDlg.value = false
    }

    async function setFcltyPymInf(obj) {
        fcltyInf.fcltyClcdNm    = obj.fcltyClcdNm
        fcltyInf.fcltyNum       = obj.fcltyNum
        yysUseParams.fcltyNum   = obj.fcltyNum
        getYysUseInfo(obj)
    }


    async function getYysUseInfo(obj) {
        prdctList.splice(0)
        useLstList.splice(0)
        await cmsApi.post('yyspym/selYysUseInfo', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    
                    setYysUseInfo(response.data.result)
                }
                else{
                    dlgInf.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    
    function setYysUseInfo(res){
        console.log(res)
        res.prdctList.forEach(itm => {
            prdctList.push(itm)
        })

        res.useLstList.forEach(itm => {
            useLstList.push(itm)
        })

        let idx = useLstList.findIndex(v=>v.srvcClcd == tabVal.value)

        yysUseParams.srvcClcd       = tabVal.value
        
        if(idx > -1){
            yysUseParams.yysUseLstPk    = useLstList[idx].yysUseLstPk 
        } else {
            yysUseParams.yysUseLstPk = 0
        }

        let obj = {}
        obj.page = 1
        obj.itemsPerPage = yysUseParams.rnLmt
        getYysUseList(obj)
    }



    async function getYysUseList(obj) {    
        tblUse.serverItems.splice(0)

        if(yysUseParams.yysUseLstPk > 0 && obj != undefined){
            tblUse.loading = true
            yysUseParams.rnStrt = ((obj.page-1) * obj.itemsPerPage)
            await cmsApi.post('yyspym/selYysUseList', yysUseParams)
                .then((response) => {
                    
                    if(response.data.statusCode == '200'){
                        tblUse.loading = false 
                        tblUse.totalItems = response.data.result.rnTot
                        response.data.result.list.forEach(item => {  
                            
                            let mjrIdx = prdctList.findIndex(v=>v.prdCd == item.mjrPrdCd)
                            item.mjrPrdCdNm = ''
                            if(mjrIdx > -1){
                                item.mjrPrdCdNm = prdctList[mjrIdx].prdNm
                            }

                            let prdIdx = prdctList.findIndex(v=>v.prdCd == item.prdCd)
                            item.prdCdNm = ''
                            if(prdIdx > -1){
                                item.prdCdNm = prdctList[prdIdx].prdNm
                                item.prdTypCd = prdctList[prdIdx].prdTypCd
                            }

                            tblUse.serverItems.push(item)
                        });                  
                    }
                    else{
                        dlgInf.$patch({ succActBtn: false })
                        if(response.data.statusCode == '610'){
                            lgn.$patch({
                                account: '',
                                name: '',
                                level: '',
                                token: '',
                                key: '',
                            })
                            noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
        
                            router.push({ name: 'LoginComp' })
                        }
                        else
                            noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                    }
                })
                .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
        }

    }
</script>
