// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { ko, en } from 'vuetify/locale'

// Vuetify
import { createVuetify } from 'vuetify'

let yysLightTheme = {
    dark: false,
    colors: {
      background: '#FFFFFF',
      surface: '#FFFFFF',
      primary: '#6200EE',
      'primary-darken-1': '#3700B3',
      secondary: '#03DAC6',
      'secondary-darken-1': '#018786',
      error: '#B00020',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FB8C00',
      white: '#FFFFFF',
      black: '#000000',
      black001: '#181818',
      grey001: '#f0f1f3',
      grey002: '#d1d6e0',
      grey003: '#eef0f3',
      grey004: '#d8dde6',
      grey005: '#20253399',
      grey101: '#616161',
      purple001: '#9933ff',
      blue001: '#005593',
      blue002: '#1B81DF',
      blue003: '#24ACEE',
      blue004: '#053866',
      blue005: '#6610f2',
      blue006: '#004dc1',
      blue101: '#e4ecfe',
      blue102: '#a1acca',
      blue103: '#3973ff',
      blue104: '#1d2939',
      blue105: '#3873ff',
      blue106: '#eff3fb',
      blue107: '#f9fbfd',
    },
  }

export default createVuetify({
    locale: {
        locale: 'ko',
        messages: { ko, en },
    },
    theme: {
        defaultTheme: 'yysLightTheme',
        themes: {
            yysLightTheme,
        },
        dark: false,
    },
    date: {
        locale: {
            en: 'en',
        },
    },
})
