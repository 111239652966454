<template>   
    <v-row no-gutters>                
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">기관번호</span>
        </v-col>
        <v-col class="bg-blue107 py-2  ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">치매전담실</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">전화번호</span>
        </v-col>
    </v-row>
    <v-row no-gutters>                
        <v-col class="pl-2 ma-0" cols="4">
            {{ info.fcltyInfo.fcltyNum }}
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            <span v-if="info.fcltyInfo.dmntTrtYn == '1'">운영</span>
            <span v-else>미운영</span>
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            <span>{{ info.fcltyInfo.fcltyTel1 }}</span>
            <span>&#45;</span>
            <span>{{ info.fcltyInfo.fcltyTel2 }}</span>
            <span>&#45;</span>
            <span>{{ info.fcltyInfo.fcltyTel3 }}</span>
        </v-col>
    </v-row>    
    <v-row no-gutters>                
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">시설명</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">시설장명</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">팩스번호</span>
        </v-col>
    </v-row>
    <v-row no-gutters>                
        <v-col class="pl-2 ma-0" cols="4">
            {{ info.fcltyInfo.fcltyClnm }}
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            {{ info.drcMmbr.name }}
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            <span>{{ info.fcltyInfo.fcltyFax1 }}</span>
            <span>&#45;</span>
            <span>{{ info.fcltyInfo.fcltyFax2 }}</span>
            <span>&#45;</span>
            <span>{{ info.fcltyInfo.fcltyFax3 }}</span>
        </v-col>
    </v-row>
    <v-divider class="my-2" color="white"></v-divider>
    <v-row no-gutters>                
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">시설구분</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">휴대폰</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">입소정원</span>
        </v-col>
    </v-row>
    <v-row no-gutters>                
        <v-col class="pl-2 ma-0" cols="4">
            {{ info.fcltyInfo.fcltyClcdNm }}
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            <span>{{ info.drcMmbr.celphn_1 }}</span>
            <span>&#45;</span>
            <span>{{ info.drcMmbr.celphn_2 }}</span>
            <span>&#45;</span>
            <span>{{ info.drcMmbr.celphn_3 }}</span>
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            {{ info.fcltyInfo.entCap }}
        </v-col>
    </v-row>
    <v-divider class="my-2" color="white"></v-divider>
    <v-row no-gutters>                
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">이메일</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="8">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">사업개시일</span>
        </v-col>
    </v-row>
    <v-row no-gutters>                
        <v-col class="pl-2 ma-0" cols="4">
            <span>{{ info.fcltyInfo.emailId }}</span>
            <span>&#64;</span>
            <span>{{ info.fcltyInfo.emailDmn }}</span>
        </v-col>
        <v-col class="pl-2 ma-0" cols="8">
            {{ $moment(info.fcltyInfo.bsnsStrtDt,'YYYYMMDD').format('YYYY-MM-DD') }}            
        </v-col>
        
    </v-row>
    <v-divider class="my-2" color="white"></v-divider>
    <v-row no-gutters>                
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">사업자번호</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">대표자명</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">업태/종목</span>
        </v-col>
    </v-row>
    <v-row no-gutters>                
        <v-col class="pl-2 ma-0" cols="4">
            {{ info.fcltyInfo.bsnsRgst }}
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            {{ info.fcltyInfo.ceo }}      
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            <span>{{ info.fcltyInfo.bsnsCndtn }}</span>
            <span>&#47;</span>
            <span>{{ info.fcltyInfo.bsnsItm }}</span>
        </v-col>
    </v-row>
    <v-divider class="my-2" color="white"></v-divider>
    <v-row no-gutters>                
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">담당자</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">연락처</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">이메일</span>
        </v-col>
    </v-row>
    <v-row no-gutters>                
        <v-col class="pl-2 ma-0" cols="4">
            {{ info.fcltyInfo.mngr }}
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            <span>{{ info.fcltyInfo.mngrTel1 }}</span>
            <span>&#45;</span>
            <span>{{ info.fcltyInfo.mngrTel2 }}</span>
            <span>&#45;</span>
            <span>{{ info.fcltyInfo.mngrTel3 }}</span> 
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            <span>{{ info.fcltyInfo.mngrEmailId }}</span>
            <span>&#64;</span>
            <span>{{ info.fcltyInfo.mngrSelectEmail }}</span>
        </v-col>
    </v-row>
    <v-divider class="my-2" color="white"></v-divider>
    <v-row no-gutters>                
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">주소</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">현금영수증</span>
        </v-col>
        <v-col class="bg-blue107 py-2 ma-0" cols="4">
            <span class="gothicA1 fnt0-9 ml-1 font-weight-bold">직인</span>
        </v-col>
    </v-row>
    <v-row no-gutters>                
        <v-col class="pl-2 ma-0" cols="4">
            <span>{{ info.fcltyInfo.addr }}</span>
            <span>{{ info.fcltyInfo.addrDtl }}</span>
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            <span v-if="info.fcltyInfo.pbilYn == '1'">신청완료({{ info.fcltyInfo.pbilId }})</span>
            <span v-else>미신청</span>
        </v-col>
        <v-col class="pl-2 ma-0" cols="4">
            <v-img :src="info.eSigInfo.imgSrc" :height="vuetify.height.value * 0.12"
            :max-height="vuetify.height.value * 0.12">
            </v-img>
        </v-col>
    </v-row>
</template>
<script setup>
     import { reactive, onMounted, watch, getCurrentInstance} from 'vue'
    import { glbDlg,  notiInf, lgnInf} from "@/state"
    import { cmsApi, remoteURL } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'
    import { useDisplay } from 'vuetify';

    const props     = defineProps(['psdObj'])
    const dlgState  = glbDlg()    
    const noti      = notiInf()
    const lgn       = lgnInf()
    const router    = useRouter()
    const $moment = getCurrentInstance().appContext.config.globalProperties.$moment
    const vuetify = useDisplay()

    let info = reactive({    
        vCd89: [],
        fcltyInfo: {
            cllCnfmYn   : '',
            fcltyClcd   : '',
            fcltyClcdNm : '',
            emailId     : '',
            bsnsStrtDt  : '',
            dmntTrtYn   : '',
            ceo         : '',
            bsnsItm     : '',
            fcltyTel3   : '',
            mngrEmailDmn: '',
            emailDmn    : '',
            cllTel1     : '',
            fcltyTel1   : '',
            cllTel3     : '',
            fcltyTel2   : '',
            cllTel2     : '',
            mngrTel3    : '',
            fcltyFax3   : '',
            addr        : '',
            bsnsRgst    : '',
            mngrTel1    : '',
            drcMmbrPk   : '',
            mngrTel2    : '',
            fcltyFax1   : '',
            fcltyFax2   : '',
            zip         : '',
            pbilId      : '',
            fcltyClnm   : '',
            drcMmbr     : '',
            drccClphn2  : '',
            drccClphn1  : '',
            fcltyNum    : '',
            drccClphn3  : '',
            bsnsCndtn   : '',
            fcltyInfPk  : '',
            mngrEmailId : '',
            entCap      : '',
            addrDtl     : '',
            mngr        : '',
            pbilYn      : '',
        },
        drcMmbr:{
            name        : '',
            mmbrPk      : '',
            celphn_1    : '',
            celphn_2    : '',
            celphn_3    : '',
        },
        eSigInfo: {            
            eSigPk      : 0,
            eSigClcd    : '',
            bzClcdPk    : 0,
            sigFileNm   : '',
            sigFilePath : '',
            sign        : null,
            imgSrc      : '',
        }
    })

    onMounted(() => {
        setInsCmnAcntAuthGrpMap(props.psdObj)
    })

    watch(() => props.psdObj, (newValue) => {
        setInsCmnAcntAuthGrpMap(newValue)
    })

    async function setInsCmnAcntAuthGrpMap(obj) {
        info.vCd89.splice(0)
        await cmsApi.post('fclty/selFcltyInfInfo', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){   
                    setFcltyInf(response.data.result)            
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })

                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    function setFcltyInf(obj){
         if(obj.codes != null && obj.codes.vCd89 != undefined){
            obj.codes.vCd89.forEach(el => {
                info.vCd89.push(el)
            });
         }
         
        if(obj.fcltyInfo != null && obj.fcltyInfo != undefined){
            info.fcltyInfo.cllCnfmYn    = obj.fcltyInfo.cllCnfmYn
            info.fcltyInfo.fcltyClcd    = obj.fcltyInfo.fcltyClcd
            info.fcltyInfo.emailId      = obj.fcltyInfo.emailId
            info.fcltyInfo.bsnsStrtDt   = (obj.fcltyInfo.bsnsStrtDt != '' && obj.fcltyInfo.bsnsStrtDt != null)?$moment(obj.fcltyInfo.bsnsStrtDt,'YYYYMMDD').format('YYYY.MM.DD'):''
            info.fcltyInfo.dmntTrtYn    = obj.fcltyInfo.dmntTrtYn
            info.fcltyInfo.ceo          = obj.fcltyInfo.ceo
            info.fcltyInfo.bsnsItm      = obj.fcltyInfo.bsnsItm
            info.fcltyInfo.fcltyTel3    = obj.fcltyInfo.fcltyTel3
            info.fcltyInfo.mngrEmailDmn = obj.fcltyInfo.mngrEmailDmn
            info.fcltyInfo.emailDmn     = obj.fcltyInfo.emailDmn
            info.fcltyInfo.cllTel1      = obj.fcltyInfo.cllTel1
            info.fcltyInfo.fcltyTel1    = obj.fcltyInfo.fcltyTel1
            info.fcltyInfo.cllTel3      = obj.fcltyInfo.cllTel3
            info.fcltyInfo.fcltyTel2    = obj.fcltyInfo.fcltyTel2
            info.fcltyInfo.cllTel2      = obj.fcltyInfo.cllTel2
            info.fcltyInfo.mngrTel3     = obj.fcltyInfo.mngrTel3
            info.fcltyInfo.fcltyFax3    = obj.fcltyInfo.fcltyFax3
            info.fcltyInfo.addr         = obj.fcltyInfo.addr
            info.fcltyInfo.bsnsRgst     = obj.fcltyInfo.bsnsRgst
            info.fcltyInfo.mngrTel1     = obj.fcltyInfo.mngrTel1
            info.fcltyInfo.drcMmbrPk    = obj.fcltyInfo.drcMmbrPk
            info.fcltyInfo.mngrTel2     = obj.fcltyInfo.mngrTel2
            info.fcltyInfo.fcltyFax1    = obj.fcltyInfo.fcltyFax1
            info.fcltyInfo.fcltyFax2    = obj.fcltyInfo.fcltyFax2
            info.fcltyInfo.zip          = obj.fcltyInfo.zip
            info.fcltyInfo.pbilId       = obj.fcltyInfo.pbilId
            info.fcltyInfo.fcltyClnm    = obj.fcltyInfo.fcltyClnm
            info.fcltyInfo.drcMmbr      = obj.fcltyInfo.drcMmbr
            info.fcltyInfo.drccClphn2   = obj.fcltyInfo.drccClphn2
            info.fcltyInfo.drccClphn1   = obj.fcltyInfo.drccClphn1
            info.fcltyInfo.fcltyNum     = obj.fcltyInfo.fcltyNum
            info.fcltyInfo.drccClphn3   = obj.fcltyInfo.drccClphn3
            info.fcltyInfo.bsnsCndtn    = obj.fcltyInfo.bsnsCndtn
            info.fcltyInfo.fcltyInfPk   = obj.fcltyInfo.fcltyInfPk
            info.fcltyInfo.mngrEmailId  = obj.fcltyInfo.mngrEmailId
            info.fcltyInfo.entCap       = obj.fcltyInfo.entCap
            info.fcltyInfo.addrDtl      = obj.fcltyInfo.addrDtl
            info.fcltyInfo.mngr         = obj.fcltyInfo.mngr
            info.fcltyInfo.pbilYn       = obj.fcltyInfo.pbilYn

            info.vCd89.forEach(cd => {
                if(cd.valcd == info.fcltyInfo.fcltyClcd) info.fcltyInfo.fcltyClcdNm = cd.valcdnm
            });
        }

        if(obj.eSigInfo != null && obj.eSigInfo != undefined){
            info.eSigInfo.eSigPk       = obj.eSigInfo.eSigPk
            info.eSigInfo.eSigClcd     = obj.eSigInfo.eSigClcd
            info.eSigInfo.bzClcdPk     = obj.eSigInfo.bzClcdPk
            info.eSigInfo.sigFileNm    = obj.eSigInfo.sigFileNm
            info.eSigInfo.sigFilePath  = obj.eSigInfo.sigFilePath
            info.eSigInfo.sign         = obj.eSigInfo.sign

            info.eSigInfo.imgSrc =  remoteURL + 'main/imgView?fileNm=' + info.eSigInfo.sigFileNm + '&filePth=' + info.eSigInfo.sigFilePath
        }

        if(obj.drcMmbr != null && obj.drcMmbr != undefined){            
            info.drcMmbr.name       = obj.drcMmbr.name
            info.drcMmbr.mmbrPk     = obj.drcMmbr.mmbrPk
            info.drcMmbr.celphn_1   = obj.drcMmbr.celphn_1
            info.drcMmbr.celphn_2   = obj.drcMmbr.celphn_2
            info.drcMmbr.celphn_3   = obj.drcMmbr.celphn_3

        }
     }

</script>
