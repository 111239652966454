<template>
    <v-form v-model="bbsForm">
        <v-row class="pa-0" no-gutters>
            <span class="gothicA1 fnt0-9 ml-1">제목</span>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-text-field
                v-model="bbsInf.title"
                class="login-theme"
                base-color="grey002" color="blue103"
                density="compact" variant="outlined"
                :rules="[rules.getRequired]"
                hide-details>
            </v-text-field>
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="3">
                <span class="gothicA1 fnt0-9 ml-1">공지여부</span>
            </v-col>
            <v-col class="" cols="3">
                <span class="gothicA1 fnt0-9 ml-1">알림여부</span>
            </v-col>
            <v-col class="" cols="6">
                <span class="gothicA1 fnt0-9 ml-1">알림기간</span>
            </v-col>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="3">
                <div class="d-flex justify-center pl-2" style="width: 60px; height: 100%;">
                    <span>
                        <v-checkbox
                            v-model="bbsInf.ntcYn"
                            class="checbox-theme opct0-6"
                            true-icon="mdi-bullhorn-variant" false-icon="mdi-bullhorn-variant-outline"
                            true-value="1" false-value="2" density="compact"
                            hide-details>
                        </v-checkbox>
                    </span>
                </div>
            </v-col>
            <v-col class="" cols="3">
                <div class="d-flex justify-center pl-2" style="width: 60px; height: 100%;">
                    <span>
                        <v-checkbox
                            v-model="bbsInf.almYn"
                            class="checbox-theme opct0-6"
                            true-icon="mdi-bell-ring" false-icon="mdi-bell-off"
                            true-value="1" false-value="2" density="compact"
                            hide-details>
                        </v-checkbox>
                    </span>
                </div>
            </v-col>
            <v-col class="d-flex align-center" cols="6">
                <CmmDtPckr v-model="bbsInf.almDtStrt" :maxDt="bbsInf.almDtEnd" :required="true"></CmmDtPckr>
                <span class="mx-2 pb-2">
                    <v-icon color="grey002" size="16">mdi-tilde</v-icon>
                </span>
                <CmmDtPckr v-model="bbsInf.almDtEnd" :minDt="bbsInf.almDtStrt" :required="true"></CmmDtPckr>
            </v-col>
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <span class="gothicA1 fnt0-9 ml-1">내용</span>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <CmmEdtr v-model="bbsInf.cntntHtml" @plainTxt="rflEdtTxt"></CmmEdtr>
            <!-- <v-textarea
                v-model="bbsInf.cntnt"
                class="txtAreaDft" color="#90CAF9"
                variant="filled" rows="13"
                no-resize hide-details>
            </v-textarea> -->
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <span class="gothicA1 fnt0-9 ml-1">자료첨부</span>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
        <CmmFileMgmt :cmnc="'yys'" :bzPk="bbsInf.ntcBbsPk" @saveFiles="callBackFiles"></CmmFileMgmt>
    </v-form>
</template>

<script setup>
    import { reactive, onMounted, watch, ref, getCurrentInstance } from 'vue'
    import { glbDlg, glbRules, notiInf, lgnInf } from "@/state"
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    import CmmDtPckr from '@/components/commons/CmmDtPckr.vue'
    import CmmEdtr from '@/components/commons/CmmEdtr.vue'
    import CmmFileMgmt from '@/components/commons/CmmFileMgmt.vue'

    const props = defineProps(['psdObj'])
    const router = useRouter()
    const dlgState = glbDlg()
    const rules = glbRules()
    const noti = notiInf()
    const lgn = lgnInf()
    const bbsForm = ref(false)
    const $moment = getCurrentInstance().appContext.config.globalProperties.$moment

    let bfrSvnFiles = []

    let bbsInf = reactive({
        ntcBbsPk: 0,
        almYn: '',
        ntcYn: '2',
        title: '',
        cntnt: '',
        cntntHtml: '',
        almDtStrt: '',
        almDtEnd: '',
        fcltyNum: '10000000000',
        crtDt: '',
        crtr: '',
    })

    onMounted(() => {
        setBbsInf(props.psdObj)
    })

    watch(() => props.psdObj, (newValue) => {
        setBbsInf(newValue)
    })
    watch(() => dlgState.getSuccActBtn, (newValue) => {
        if(dlgState.getDlgTyp == '101' && newValue)
            savNtsBbs()
    })
    watch(() => dlgState.getCnclActBtn, (newValue) => {
        if(dlgState.getDlgTyp == '101' && newValue)
            rmNtsBbs(bbsInf.ntcBbsPk)
    })

    // YYS공지 저장
    async function setNtsBbs(obj) {
        await cmsApi.post('bbs/insNtsBbs', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    dlgState.$patch({ isDlg: false, succActBtn: false })
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // YYS공지 삭제
    async function rmNtsBbs(bbsPk) {
        let obj = {}
        obj.fcltyNum = '10000000000'
        obj.ntcBbsPk = bbsPk

        await cmsApi.post('bbs/delNtsBbs', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    dlgState.$patch({ isDlg: false, cnclActBtn: false })
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    function savNtsBbs(){
        if(bbsForm.value){
            let obj = Object.assign({}, bbsInf)
            obj.almDtStrt = $moment(obj.almDtStrt, 'YYYY-MM-DD').format('YYYYMMDD')
            obj.almDtEnd = $moment(obj.almDtEnd, 'YYYY-MM-DD').format('YYYYMMDD')
            obj.files = []
            
            if(Array.isArray(bfrSvnFiles)){
                bfrSvnFiles.forEach(idx => {
                    if(idx.atchdFilePk == undefined)
                        obj.files.push(idx)
                });
            }
        
            setNtsBbs(obj)
        }
        else{
            let msg = '미입력 데이터 또는 규칙에 맞지않는 데이터를 확인해주세요.'
            noti.$patch({ stt: true, ttl: 'Warning', cntnt: msg, type: 'warning', tmOut: 2500 })
            dlgState.$patch({ succActBtn: false })
        }
    }

    function setBbsInf(obj) {
        bbsInf.ntcBbsPk = obj.ntcBbsPk
        bbsInf.almYn = obj.almYn
        bbsInf.ntcYn = obj.ntcYn
        bbsInf.title = obj.title
        bbsInf.cntnt = obj.cntnt
        bbsInf.cntntHtml = obj.cntntHtml
        if(obj.almDtStrt != '')
            bbsInf.almDtStrt = $moment(obj.almDtStrt, 'YYYY.MM.DD').format('YYYY-MM-DD')
        if(obj.almDtEnd != '')
            bbsInf.almDtEnd = $moment(obj.almDtEnd, 'YYYY.MM.DD').format('YYYY-MM-DD')
        bbsInf.fcltyNum = obj.fcltyNum
        bbsInf.crtDt = obj.crtDt
        bbsInf.crtr = obj.crtr
    }

    function rflEdtTxt(txt) {
        bbsInf.cntnt = txt
    }

    function callBackFiles(arr) {
        bfrSvnFiles = arr
    }

</script>