<template>
    <v-app>
        <!-- body -->
        <v-main>
            <router-view></router-view>
        </v-main>

        <!-- message -->
        <v-snackbar
            v-model="snckInf.stt" :timeout="snckInf.tmOut"
            class="globar-snkBar-theme" color="white" location="bottom"
            min-width="300" absolute>
            <v-alert
                :type="snckInf.typ"
                :title="snckInf.ttl"
                :text="snckInf.cntnt">
            </v-alert>
        </v-snackbar>

        <!-- global Dialoge -->
        <CmmGlbDlg></CmmGlbDlg>
    </v-app>
</template>

<script setup>
    import 'animate.css';

    import { watchEffect, onMounted, watch, reactive } from 'vue';
    import { useDisplay } from 'vuetify';
    import { notiInf, displayInfStore } from "@/state";

    import CmmGlbDlg from '@/components/commons/CmmGlbDlg.vue'

    require('./styles/css/cmm.css')


    const vuetify = useDisplay()
    const state = displayInfStore()
    const noti = notiInf()

    let snckInf = reactive({
        stt: false,
        tmOut: 0,
        cntnt: '',
        ttl: '',
        typ: '',
    })
    
    onMounted(() => {
        snckInf.tmOut = noti.getNotiTmOut
        snckInf.cntnt = noti.getNotiCntnt
        snckInf.ttl = noti.getNotiTtl
        snckInf.typ = noti.getNotiTyp
    })

    watch(() => notiInf().stt, (newValue) => {
        snckInf.stt = newValue
    })

    watch(() => notiInf().tmOut, (newValue) => {
        snckInf.tmOut = newValue
    })

    watch(() => notiInf().ttl, (newValue) => {
        snckInf.ttl = newValue
    })

    watch(() => notiInf().cntnt, (newValue) => {
        snckInf.cntnt = newValue
    })

    watch(() => notiInf().type, (newValue) => {
        snckInf.typ = newValue
    })

    watch(() => snckInf.stt, (newValue) => {
        if(newValue == false){
            setTimeout(function(){
                noti.$patch({ stt: newValue, ttl: 'Info', cntnt: 'information', type: 'info', tmOut: 2500 })
            }, 1000)
        }
    })

    watchEffect(() => {
        state.width = vuetify.width.value
        state.height = vuetify.height.value
        state.isMobile = vuetify.mobile.value

        if(vuetify.xxl.value)
            state.breakPoint = 'xxl'
        else if(vuetify.xl.value)
            state.breakPoint = 'xl'
        else if(vuetify.lg.value)
            state.breakPoint = 'lg'
        else if(vuetify.md.value)
            state.breakPoint = 'md'
        else if(vuetify.sm.value)
            state.breakPoint = 'sm'
        else if(vuetify.xs.value)
            state.breakPoint = 'xs'
        else
            state.breakPoint = 'err'
    })
</script>