<template>
    <v-row class="pa-0" no-gutters>
        <span class="gothicA1 fnt0-9 ml-1">내용</span>
    </v-row>
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
    <v-row class="pa-0" no-gutters>
        <v-col class="pa-2 overflow-x-auto" cols="12">
            <p>{{errInf.errCntnt1}}</p>
            <p>{{errInf.errCntnt2}}</p>
        </v-col>
    </v-row>
    <v-row class="pa-0" no-gutters>
        <v-col class="d-flex justify-start align-center" cols="4">
            <span class="gothicA1 fnt0-9 ml-1">로그 파일 내용</span>
            <v-btn  @click="fileDown()"
                variant="plain"
                icon>
                <v-icon size="20" color="blue">mdi-arrow-down-bold-box-outline</v-icon>
            </v-btn>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
    <v-row class="pa-0" no-gutters>
        <v-col class="pa-2" cols="12">
            <v-textarea
                v-model="errInf.fileCntnt"
                class="login-theme"
                base-color="grey002" color="blue103"
                density="compact" variant="outlined"
                rows="15"
                hide-details>
            </v-textarea>
        </v-col>
    </v-row>
</template>
<script setup>
    import { reactive, onMounted, watch,} from 'vue'
    import { lgnInf, notiInf, } from "@/state";
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'
    
    const props = defineProps(['psdObj'])
    const router = useRouter()
    const noti = notiInf()
    const lgn = lgnInf()

    let errInf = reactive({
        crtDt       : '',
        crtr        : '',
        errCntnt1   : '',
        errCntnt2   : '',
        fileNm      : '',
        filePth     : '',
        fileCntnt   : '',
    })

    onMounted(() => {
        setErrInf(props.psdObj)
    })

    watch(() => props.psdObj, (newValue) => {
        setErrInf(newValue)
    })

    function setErrInf(obj) {
        errInf.crtDt = obj.crtDt
        errInf.crtr = obj.crtr
        errInf.errCntnt1 = obj.errCntnt1
        errInf.errCntnt2 = obj.errCntnt2
        errInf.fileNm = obj.fileNm
        errInf.filePth = obj.filePth

        selErrLogFileLoad()
    }

    async function selErrLogFileLoad() {
        let obj = {
            filePth     : errInf.filePth,
            fileNm      : errInf.fileNm,      
        }

        await cmsApi.post('cmm/selErrLogFileLoad', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){   
                    if(response.data.result.fileCntnt != undefined) errInf.fileCntnt = response.data.result.fileCntnt
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // 파일 다운로드
    async function fileDown(){
        let obj = {
          atchdFilePk : 1,
          filePth     : errInf.filePth,
          fileNm      : errInf.fileNm,
        }
        await cmsApi({
            method: 'post',
            url: 'cmm/fileDownload',
            responseType: 'arraybuffer',
            data: obj
        }).then((response) => {
            createFile(response)
        })
        .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }
    
    function createFile(res){
        let contentDisposition = decodeURI(res.headers['content-disposition'])
       
        let link = document.createElement('a')
        let url = window.URL.createObjectURL(new Blob([res.data]))
        let fileName = 'unknown'

        if (contentDisposition) {
            const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
            if (fileNameMatch)
                [ , fileName ] = fileNameMatch.split('=')
        }
        link.href = url

        fileName = fileName.replaceAll('"', '')
        link.setAttribute('download', `${fileName}`)
        link.style.cssText = 'display:none'
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

</script>