<template>
    <v-sheet min-width="675" max-width="888" rounded="lg">
        <v-row class="pa-2 rounded-t bg-blue107 srndBrdr-grey002" no-gutters>
            <v-col class="d-flex" cols="12">
                <span class="d-inline-block mr-1" style="width: 120px; height: 100%;">
                    <v-select
                        v-model="fntFmly"
                        class="slt-Theme"
                        base-color="grey002" bg-color="white" color="blue103"
                        density="compact" variant="outlined"
                        item-title="valcdnm" item-value="valcd"
                        :items="fntTyp" @update:modelValue="rflFntChgr"
                        hide-details>
                        <template v-slot:item="{ item, props }">
                            <v-list-item
                                :class="[fntFmly == item.value ? 'bg-blue106 text-blue' : '']"
                                density="compact" @click="props.onClick">
                                <span :class="['fnt0-8', fntFmly == item.value ? 'text-blue' : '']">{{ item.title }}</span>
                            </v-list-item>
                        </template>
                    </v-select>
                </span>
                <span class="d-inline-block" style="width: 76px; height: 100%;">
                    <v-select
                        v-model="fntSize"
                        class="slt-Theme"
                        base-color="grey002" bg-color="white" color="blue103"
                        density="compact" variant="outlined"
                        item-title="valcdnm" item-value="valcd"
                        :items="fntSz" @update:modelValue="rflFntSzChgr"
                        hide-details>
                        <template v-slot:item="{ item, props }">
                            <v-list-item
                                :class="[fntSize == item.value ? 'bg-blue106 text-blue' : '']"
                                density="compact" @click="props.onClick">
                                <span :class="['fnt0-8', fntSize == item.value ? 'text-blue' : '']">{{ item.title }}</span>
                            </v-list-item>
                        </template>
                    </v-select>
                </span>
                <v-divider class="mx-2" vertical></v-divider>
                <v-btn-toggle
                    v-model="edtHstr"
                    class="btnGrp-Theme bg-white"
                    variant="outlined" density="compact"
                    divided>
                    <v-btn :disabled="!isBtnGrp[8]" @click="rflEdtHstr(1)" icon="mdi-arrow-u-left-top"></v-btn>
                    <v-btn :disabled="!isBtnGrp[9]" @click="rflEdtHstr(2)" icon="mdi-arrow-u-right-top"></v-btn>
                </v-btn-toggle>
                <v-divider class="mx-2" vertical></v-divider>
                <v-btn-toggle
                    v-model="edtAlign"
                    class="btnGrp-Theme bg-white"
                    variant="outlined" density="compact"
                    divided>
                    <v-btn :active="isBtnGrp[0]" @click="rflTxtAlign('left')" icon="mdi-format-align-left"></v-btn>
                    <v-btn :active="isBtnGrp[1]" @click="rflTxtAlign('center')" icon="mdi-format-align-center"></v-btn>
                    <v-btn :active="isBtnGrp[2]" @click="rflTxtAlign('right')" icon="mdi-format-align-right"></v-btn>
                    <v-btn :active="isBtnGrp[3]" @click="rflTxtAlign('justify')" icon="mdi-format-align-justify"></v-btn>
                </v-btn-toggle>
                <v-divider class="mx-2" vertical></v-divider>
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn-toggle
                            v-bind="props"
                            class="btnGrp-Theme bg-white"
                            variant="outlined" density="compact"
                            divided>
                            <v-btn icon="mdi-palette-outline"></v-btn>
                        </v-btn-toggle>
                    </template>
                    <div class="pt-1">
                        <v-card
                            class="pt-2 pl-3"
                            style="padding-right: 10px; padding-bottom: 6px;"
                            elevation="0" width="204" :border="true">
                            <v-row class="pa-0" no-gutters>
                                <v-col
                                    v-for="(list, i) in clrList" :key="i"
                                    @click="rflEdtClr(list)"
                                    class="pr-1-qrtr-2 pb-1-qrtr-2"
                                    cols="1">
                                    <v-hover
                                        v-slot="{ isHovering, props }">
                                        <v-card
                                            v-bind="props"
                                            :elevation="isHovering ? 3 : 0"
                                            width="13" height="13" :color="list" rounded="sm">
                                            <span
                                                v-if="isHovering"
                                                class="d-inline-block srndBrdr-white"
                                                style="position: absolute; top: 0px; left: 0px; width: 73%; height: 73%; margin: 2px;">
                                            </span>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </v-card>
                    </div>
                </v-menu>
                <v-divider class="mr-1" color="blue107" vertical></v-divider>
                <v-btn-toggle
                    v-model="txtHghl"
                    class="btnGrp-Theme bg-white"
                    variant="outlined" density="compact"
                    divided>
                    <v-btn :active="isBtnGrp[4]" @click="rflTxtStyle(1)" icon="mdi-format-bold"></v-btn>
                    <v-btn :active="isBtnGrp[5]" @click="rflTxtStyle(2)" icon="mdi-format-underline"></v-btn>
                    <v-btn :active="isBtnGrp[6]" @click="rflTxtStyle(3)" icon="mdi-format-italic"></v-btn>
                    <v-btn :active="isBtnGrp[7]" @click="rflTxtStyle(4)" icon="mdi-format-strikethrough"></v-btn>
                    <v-btn :active="isBtnGrp[18]" @click="setBook" icon="mdi-book-outline"></v-btn>
                </v-btn-toggle>
                <v-divider class="mx-2" vertical></v-divider>
                <span class="d-inline-block" style="width: 28px; height: auto;">
                    <v-file-input
                        v-model="files"
                        class="vFile-Theme"
                        prepend-icon="mdi-image-outline"
                        hide-details multiple>
                    </v-file-input>
                </span>
            </v-col>
            <v-col class="" cols="12">
                <v-divider class="my-2"></v-divider>
            </v-col>
            <v-col class="d-flex" cols="12">
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn-toggle
                            v-bind="props"
                            class="btnGrp-Theme bg-white"
                            variant="outlined" density="compact"
                            divided>
                            <v-btn icon="mdi-shape-plus-outline"></v-btn>
                        </v-btn-toggle>
                    </template>
                    <div class="pt-1">
                        <v-card
                            class="pt-2 pl-3"
                            style="padding-right: 10px; padding-bottom: 6px;"
                            elevation="0" width="288" :border="true">
                            <v-row class="pa-0" no-gutters>
                                <span
                                    v-for="(list, i) in iconList" :key="i"
                                    class="mr-1-qrtr-2 mb-1-qrtr-1">
                                    <v-hover
                                        v-slot="{ isHovering, props }">
                                        <span
                                            :class="['d-flex pa-1 rounded elevation-0', isHovering ? 'bg-blue105' : '']"
                                            @click="setIcon(list)">
                                            <v-icon
                                                v-bind="props" :color="isHovering ? 'white' : 'black'" size="14">
                                                {{ list }}
                                            </v-icon>
                                        </span>
                                    </v-hover>
                                </span>
                            </v-row>
                        </v-card>
                    </div>
                </v-menu>
                <v-divider class="mx-2" vertical></v-divider>
                <v-btn-toggle
                    v-model="extnOpt"
                    class="btnGrp-Theme bg-white"
                    variant="outlined" density="compact"
                    divided>
                    <v-btn :active="isBtnGrp[13]" @click="setAlertBox" icon="mdi-alert-box-outline"></v-btn>
                    <v-btn :active="isBtnGrp[10]" @click="isBtnGrp[10] ? unsetLink() : setLink()" icon="mdi-link-variant"></v-btn>
                    <v-btn @click="setVideoLink" icon="mdi-television-play"></v-btn>
                </v-btn-toggle>
                <v-divider class="mx-2" vertical></v-divider>
                <v-btn-toggle
                    v-model="shpGrp"
                    class="btnGrp-Theme bg-white"
                    variant="outlined" density="compact"
                    divided>
                    <v-btn @click="setHorizontalLine" icon="mdi-minus"></v-btn>
                    <v-btn :active="isBtnGrp[11]" @click="setList(1)" icon="mdi-format-list-bulleted"></v-btn>
                    <v-btn :active="isBtnGrp[12]" @click="setList(2)" icon="mdi-format-list-numbered"></v-btn>
                </v-btn-toggle>
                <v-divider class="mx-2" vertical></v-divider>
                <v-btn-toggle
                    v-model="hdgGrp"
                    class="btnGrp-Theme bg-white"
                    variant="outlined" density="compact"
                    divided>
                    <v-btn :active="isBtnGrp[14]" @click="toggleHdg(1)" icon="mdi-format-header-1"></v-btn>
                    <v-btn :active="isBtnGrp[15]" @click="toggleHdg(2)" icon="mdi-format-header-2"></v-btn>
                    <v-btn :active="isBtnGrp[16]" @click="toggleHdg(3)" icon="mdi-format-header-3"></v-btn>
                    <v-btn :active="isBtnGrp[17]" @click="toggleHdg(4)" icon="mdi-format-header-4"></v-btn>
                </v-btn-toggle>
                <v-divider class="mx-2" vertical></v-divider>
                <v-btn-toggle
                    v-model="tblGrp"
                    class="btnGrp-Theme bg-white"
                    variant="outlined" density="compact"
                    divided>
                    <v-btn @click="cnfgTbl(1)" icon="mdi-table-plus"></v-btn>
                    <v-btn @click="cnfgTbl(2)" icon="mdi-table-remove"></v-btn>
                    <v-btn @click="cnfgTbl(3)" icon="mdi-table-column-plus-before"></v-btn>
                    <v-btn @click="cnfgTbl(4)" icon="mdi-table-column-plus-after"></v-btn>
                    <v-btn @click="cnfgTbl(5)" icon="mdi-table-column-remove"></v-btn>
                    <v-btn @click="cnfgTbl(6)" icon="mdi-table-row-plus-before"></v-btn>
                    <v-btn @click="cnfgTbl(7)" icon="mdi-table-row-plus-after"></v-btn>
                    <v-btn @click="cnfgTbl(8)" icon="mdi-table-row-remove"></v-btn>
                    <v-btn @click="cnfgTbl(9)" icon="mdi-table-merge-cells"></v-btn>
                    <v-btn @click="cnfgTbl(10)" icon="mdi-page-layout-header"></v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <editor-content class="tiptapEditor srndBrdr-grey002 rounded-b initBrdr-top" :editor="editor"></editor-content>
    </v-sheet>
</template>

<script setup>
    import { useEditor, EditorContent } from '@tiptap/vue-3'
    import StarterKit from '@tiptap/starter-kit'
    import TextStyle from '@tiptap/extension-text-style'
    import FontFamily from '@tiptap/extension-font-family'
    import TextAlign from '@tiptap/extension-text-align'
    import Underline from '@tiptap/extension-underline'
    import Color from '@tiptap/extension-color'
    import FocusClasses from '@tiptap/extension-focus'
    //import Image from '@tiptap/extension-image'
    import Link from '@tiptap/extension-link'
    import VideoLink from '@tiptap/extension-youtube'
    import Table from '@tiptap/extension-table'
    import TableCell from '@tiptap/extension-table-cell'
    import TableHeader from '@tiptap/extension-table-header'
    import TableRow from '@tiptap/extension-table-row'
    import { ImageResize } from '../../plugins/imageResizer';
    import { AlertBox, chgFntSz, addIcon, bookMark } from '@/plugins/editorModules';

    import { onMounted, onBeforeUnmount, watch, ref } from 'vue'
    import { useRouter } from 'vue-router'

    import { cmsApi, remoteURL } from '@/plugins/yysCmsApi';
    import { lgnInf, notiInf } from "@/state";

    const props = defineProps(['modelValue'])
    const emit = defineEmits(['update:modelValue', 'plainTxt'])
    const router = useRouter()
    const lgn = lgnInf()
    const noti = notiInf()

    // tiptap 에디터 객체 작성
    const editor = useEditor({
        content: '',
        autofocus: true,
        editorProps: {
            attributes: { class: '' },
        },
        extensions: [
            StarterKit.configure({
                history:  {
                    depth: 120,
                    newGroupDelay: 400,
                },
                paragraph: true,
                // Configure an included extension
                heading: {
                    levels: [1, 2, 3, 4],
                },
                horizontalRule: {
                    HTMLAttributes: {
                        class: 'hrznt-Theme'
                    }
                },
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
                alignments: ['left', 'center', 'right', 'justify'],
                defaultAlignment: 'left',
            }),
            FocusClasses.configure({
                className: 'has-focus',
                mode: 'all',
            }),
            Link.configure({
                openOnClick: false,
                HTMLAttributes: {
                    rel: 'noopener noreferrer',
                    target: '_self',
                },
            }),
            VideoLink.configure({
                width: 480,
                height: 320,
                ccLanguage: 'ko',
                interfaceLanguage: 'ko',
                disableKBcontrols: 'true',
                modestBranding: 'true',
            }),
            Table.configure({
                resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCell,
            ImageResize,
            AlertBox,
            Underline,
            TextStyle,
            FontFamily,
            Color,
            chgFntSz,
            bookMark.configure({
                HTMLAttributes: {
                    class: 'anchor',
                },
            }),
            addIcon.configure({
                inline: true,
                HTMLAttributes: {
                    class: 'edtMdi',
                },
            }),
        ],
        onUpdate: ({ editor }) => {
            let content = editor.getHTML()
            emit('update:modelValue', content)
            emit('plainTxt', editor.getText())
        },
    })

    let fntFmly = ref('Noto Sans KR')
    let fntTyp = [
        { valcdnm: 'Noto Sans', valcd: 'Noto Sans KR' },
        { valcdnm: 'Gothic', valcd: 'Gothic A1' },
        { valcdnm: 'Do Hyeon', valcd: 'Do Hyeon' }
    ]
    let fntSize = ref(16)
    let fntSz = [
        { valcdnm: '10pt', valcd: 10 },
        { valcdnm: '12pt', valcd: 12 },
        { valcdnm: '14pt', valcd: 14 },
        { valcdnm: '16pt', valcd: 16 },
        { valcdnm: '20pt', valcd: 20 },
        { valcdnm: '24pt', valcd: 24 },
        { valcdnm: '32pt', valcd: 32 },
        { valcdnm: '64pt', valcd: 64 },
    ]

    let clrList = [
        '#ff0000', '#ff6c00', '#ffaa00', '#ffef00', '#a6cf00', '#009e25', '#00b0a2', '#0075c8',
        '#3a32c3', '#7820b9', '#ef007c', '#000000', '#252525', '#464646', '#636363', '#7d7d7d',
        '#9a9a9a', '#ffe8e8', '#f7e2d2', '#f5eddc', '#f5f4e0', '#edf2c2', '#def7e5', '#d9eeec',
        '#c9e0f0', '#d6d4eb', '#e7dbed', '#f1e2ea', '#acacac', '#c2c2c2', '#cccccc', '#e1e1e1',
        '#ebebeb', '#ffffff', '#e97d81', '#e19b73', '#d1b274', '#cfcca2', '#cfcca2', '#61b977',
        '#53aea8', '#518fbb', '#6a65bb', '#9a54ce', '#e573ae', '#5a504b', '#767b86', '#951015',
        '#6e391a', '#785c25', '#5f5b25', '#4c511f', '#1c4827', '#0d514c', '#1b496a', '#2b285f',
        '#45245b', '#721947', '#352e2c', '#3c3f45'
    ]

    let iconList = [
        'mdi-help-circle-outline', 'mdi-alert-circle-outline', 'mdi-lightbulb-outline', 'mdi-bullhorn-variant-outline',
        'mdi-check', 'mdi-help', 'mdi-alert-outline', 'mdi-circle', 'mdi-square-rounded', 'mdi-chevron-right-box-outline',
        'mdi-chevron-right-circle-outline'
    ]

    let isBtnGrp = ref(
        [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false]
        )

    let edtHstr = ref(null)
    let edtAlign = ref(null)
    let txtHghl = ref(null)
    let extnOpt = ref(null)
    let shpGrp = ref(null)
    let tblGrp = ref(null)
    let hdgGrp = ref(null)
    let cntntChc = false
    let files = ref(null)
 
    onMounted(() => {

    })

    onBeforeUnmount(() => {
        editor.value?.destroy()
    })

    watch(() => props.modelValue, (newValue) => {
        if (cntntChc)
            return
        else{
            editor.value?.commands.setContent(newValue, false)
            cntntChc = true
        }
    })

    watch(() => editor.value?.isActive( { textAlign: 'left' }), (newValue) => {
        isBtnGrp.value.splice(0, 1, newValue)
    })
    watch(() => editor.value?.isActive( { textAlign: 'center' }), (newValue) => {
        isBtnGrp.value.splice(1, 1, newValue)
    })
    watch(() => editor.value?.isActive( { textAlign: 'right' }), (newValue) => {
        isBtnGrp.value.splice(2, 1, newValue)
    })
    watch(() => editor.value?.isActive( { textAlign: 'justify' }), (newValue) => {
        isBtnGrp.value.splice(3, 1, newValue)
    })

    watch(() => editor.value?.isActive('bold'), (newValue) => {
        isBtnGrp.value.splice(4, 1, newValue)
    })
    watch(() => editor.value?.isActive('underline'), (newValue) => {
        isBtnGrp.value.splice(5, 1, newValue)
    })
    watch(() => editor.value?.isActive('italic'), (newValue) => {
        isBtnGrp.value.splice(6, 1, newValue)
    })
    watch(() => editor.value?.isActive('strike'), (newValue) => {
        isBtnGrp.value.splice(7, 1, newValue)
    })
    watch(() => editor.value?.isActive('link'), (newValue) => {
        isBtnGrp.value.splice(10, 1, newValue)
    })

    watch(() => editor.value?.can().undo(), (newValue) => {
        isBtnGrp.value.splice(8, 1, newValue)
    })
    watch(() => editor.value?.can().redo(), (newValue) => {
        isBtnGrp.value.splice(9, 1, newValue)
    })

    watch(() => editor.value?.isActive('bulletList'), (newValue) => {
        isBtnGrp.value.splice(11, 1, newValue)
    })
    watch(() => editor.value?.isActive('orderedList'), (newValue) => {
        isBtnGrp.value.splice(12, 1, newValue)
    })

    watch(() => editor.value?.isActive('AlertBox'), (newValue) => {
        isBtnGrp.value.splice(13, 1, newValue)
    })

    watch(() => editor.value?.isActive('heading', { level: 1 } ), (newValue) => {
        isBtnGrp.value.splice(14, 1, newValue)
    })
    watch(() => editor.value?.isActive('heading', { level: 2 } ), (newValue) => {
        isBtnGrp.value.splice(15, 1, newValue)
    })
    watch(() => editor.value?.isActive('heading', { level: 3 } ), (newValue) => {
        isBtnGrp.value.splice(16, 1, newValue)
    })
    watch(() => editor.value?.isActive('heading', { level: 4 } ), (newValue) => {
        isBtnGrp.value.splice(17, 1, newValue)
    })
    watch(() => editor.value?.isActive('bookMark'), (newValue) => {
        isBtnGrp.value.splice(18, 1, newValue)
    })

    watch(() => files.value, (newValue) => {
        let reg = /(.*?)\.(jpg|jpeg|jpe|png|gif|bmp|tif|tiff|webp)$/
        let formData = new FormData()

        if(files.value != null){
            newValue.forEach(idx => {
            if(idx.name.match(reg))
                formData.append('files', idx)
            })
      
            setEdtImg(formData)
        }
    })

    // 에디터 이미지 업로드
    async function setEdtImg(frm) {
        let cnt = 0
        for (let key of frm.keys()) {
            if(key == 'files')
                cnt += 1
        }

        if(cnt > 0)
            await cmsApi.post('cmm/editFileUpload', frm)
                .then((response) => {
                    if(response.data.statusCode == '200'){
                        response.data.files.forEach(idx => {
                            let path = remoteURL + 'imgCmm/imgPrview?'
                            let fileName = 'fileNm=' + idx.fileNm
                            let filePath = 'filePth=' + idx.filePth
                            let tmpImg = new Image()
                            tmpImg.src = path + filePath + '&' + fileName
                            tmpImg.onload = function(){
                                let calc = this.width / this.height
                              
                                editor.value?.chain().focus().setImage({
                                    src: path + filePath + '&' + fileName,
                                    width: 300,
                                    height: Math.ceil(300/calc)
                                }).run()
                            };

                        });
                        files.value = null
                    }
                    else{
                        if(response.data.statusCode == '610'){
                            lgn.$patch({
                                account: '',
                                name: '',
                                level: '',
                                token: '',
                                key: '',
                            })
                            noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
        
                            router.push({ name: 'LoginComp' })
                        }
                        else
                            noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                    }
                })
                .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
        else
            noti.$patch({ stt: true, ttl: 'Warning', cntnt: '이미지 형식 파일만 업로드 가능합니다.', type: 'warning', tmOut: 2500 })
    }

    function rflFntSzChgr() {
        editor.value?.chain().focus().setFontSize(fntSize.value).run()
    }

    function rflFntChgr() {
        let fntStr = fntFmly.value + ', sans-serif;'
        editor.value?.chain().focus().setFontFamily(fntStr).run()
    }

    function rflTxtAlign(v) {
        editor.value?.chain().focus().setTextAlign(v).run()
    }

    function rflTxtStyle(idx) {
        switch (idx) {
            case 1:
                editor.value?.chain().focus().toggleBold().run()
                break;
            case 2:
                editor.value?.chain().focus().toggleUnderline().run()
                break;
            case 3:
                editor.value?.chain().focus().toggleItalic().run()
                break;
            case 4:
                editor.value?.chain().focus().toggleStrike().run()
                break;
            default:
                break;
        }
    }

    function rflEdtHstr(idx) {
        switch (idx) {
            case 1:
                editor.value?.chain().focus().undo().run()
                break;
            case 2:
                editor.value?.chain().focus().redo().run()
                break;
            default:
                break;
        }
    }

    function rflEdtClr(txt) {
        editor.value?.chain().focus().setColor(txt).run()
    }

    function setLink() {
        let previousUrl = editor.value?.getAttributes('link').href
        let url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null)
            return

        // empty
        if (url === '') {
            unsetLink()

            return
        }

        // update link
        editor.value?.chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: url })
            .run()
    }

    function unsetLink() {
        editor.value?.chain().focus().extendMarkRange('link').unsetLink().run()
    }

    function setVideoLink() {
        let url = prompt('삽입할 동영상 링크 주소를 입력해 주세요.')
     
        if(url != null){
            editor.value?.commands.setYoutubeVideo({
                src: url,
                width: Math.max(320, parseInt(480, 10)) || 640,
                height: Math.max(180, parseInt(320, 10)) || 480,
            })
        }
    }

    function setAlertBox() {
        editor.value?.chain().focus().toggleAlertBox().run()
    }

    function setHorizontalLine() {
        editor.value?.chain().focus().setHorizontalRule().run()
    }

    function setList(idx) {
        switch (idx) {
            case 1:
                editor.value?.chain().focus().toggleBulletList().run()
                break;
            case 2:
                editor.value?.chain().focus().toggleOrderedList().run()
                break;
            default:
                break;
        }
    }

    function cnfgTbl(idx) {
        switch (idx) {
            case 1:     // 테이블 삽입
                editor.value?.chain().focus().insertTable({ rows: 3, cols: 2, withHeaderRow: true }).run()
                break;
            case 2:     // 테이블 제거
                editor.value?.chain().focus().deleteTable().run()
                break;
            case 3:     // 이전 행 추가
                editor.value?.chain().focus().addColumnBefore().run()
                break;
            case 4:     // 이후 행 추가
                editor.value?.chain().focus().addColumnAfter().run()
                break;
            case 5:     // 행 삭제
                editor.value?.chain().focus().deleteColumn().run()
                break;
            case 6:     // 이전 열 추가
                editor.value?.chain().focus().addRowBefore().run()
                break;
            case 7:     // 이후 열 추가
                editor.value?.chain().focus().addRowAfter().run()
                break;
            case 8:     // 열 제거
                editor.value?.chain().focus().deleteRow().run()
                break;
            case 9:     // 셀 병합 또는 나누기
                editor.value?.chain().focus().mergeOrSplit().run()
                break;
            case 10:     // 테이블 헤더로 변환
                editor.value?.chain().focus().toggleHeaderCell().run()
                break;
            default:
                break;
        }
    }

    function toggleHdg(idx) {
        editor.value?.chain().focus().toggleHeading({ level: idx }).run()
    }

    function setIcon(icon) {
        editor.value?.chain().focus().setIcon({class: icon}).run()
    }

    function setBook() {
        if(editor.value?.isActive('bookMark'))
            editor.value?.chain().focus().unsetMark('bookMark', { extendEmptyMarkRange: true }).run()
        else{
            let uuid = prompt('Enter BookMark ID')
            editor.value?.chain().focus().setBookMark({ id: uuid }).run()
        }
    }

</script>

<style>
    .has-focus { border-radius: 3px; box-shadow: none; }

    .ProseMirror p { margin-bottom: 0px !important; }
    .ProseMirror {
        border-radius: 6px !important; border-top-left-radius: 0 !important; border-top-right-radius: 0 !important;
        width: 100%; height: 340px; overflow-y: auto; padding: 12px;
    }
    .ProseMirror:focus { outline: none; }
    .ProseMirrorMenu {
        box-shadow: 0px 0px 1px -2px rgb(0 0 0 / 20%), 0px 1px 2px 0px rgb(0 0 0 / 14%), 0px 0px 5px 0px rgb(0 0 0 / 12%) !important;
    }

    .tiptapEditor p { min-height: 24px; }
    .tiptapEditor blockquote { border-left: 0.25rem solid #dfe2e5; padding-left: 1rem; margin: 4px 0 !important; }
    .tiptapEditor img { max-width: 100%; }
    .tiptapEditor hr { margin: 12px 0 9px 0 !important; }
    .tiptapEditor ul { padding: 0 23px; }
    .tiptapEditor ol { padding: 0 20px; }

    .tiptapEditor table { border-collapse: collapse; table-layout: fixed; width: 100%; margin: 0; overflow: hidden; }
    .tiptapEditor table td {
        min-width: 1em; border: 1px solid #dee2e6; padding: 3px 5px; vertical-align: top; box-sizing: border-box;
        position: relative; margin-bottom: 0;
    }
    .tiptapEditor table th {
        min-width: 1em; border: 1px solid #dee2e6; padding: 3px 5px; vertical-align: top; box-sizing: border-box;
        position: relative; margin-bottom: 0; font-weight: bold; text-align: left; background-color:rgba(0, 0, 0, .05);
    }
    .tiptapEditor table .selectedCell:after {
        z-index: 2; position: absolute; content: ""; background: rgba(200, 200, 255, 0.4);
        left: 0; right: 0; top: 0; bottom: 0; pointer-events: none;
    }
    .tiptapEditor table .column-resize-handle {
        position: absolute; top: 0; right: -2px; bottom: -2px; width: 4px; background-color: #adf;
        pointer-events: none;
    }
    .tiptapEditor table p { margin: 0; }

    .tableWrapper { padding: 1rem 0; overflow-x: auto;}
    .resize-cursor { cursor: ew-resize; cursor: col-resize; }

    .img-resizer{ width: 200px; }

    .btnGrp-Theme { height: 30px !important; }
    .btnGrp-Theme button .v-btn__content i { font-size: 1.2rem; color: #a1acca; }

    .alertBox-Theme {
        display: block; margin-bottom: 1rem; padding: 0.4rem 1.2rem; line-height: 1.3;
        border-radius: 0.25rem; border-style: solid; border-width: 0 0 0 8px;
        border-color: #004dc1; background-color: #f8f8ff; word-break: break-all;
    }

    .edtMdi:before{
        display: inline-block; font: normal normal normal 20px/1 "Material Design Icons"; font-size: 20px;
        text-rendering: auto; line-height: inherit; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
    }

    .hrznt-Theme { opacity: .2; }
</style>