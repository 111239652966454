<template>
    <v-form v-model="bbsForm">
        <v-row class="pa-0" no-gutters>
            <span class="gothicA1 fnt0-9 ml-1">제목</span>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-text-field
                v-model="bbsInf.bbsTitle"
                class="login-theme"
                base-color="grey002" color="blue103"
                density="compact" variant="outlined"
                :rules="[rules.getRequired]"
                hide-details>
            </v-text-field>
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col v-if="bbsCfg.bbsCfNotiYn == '1'" class="" cols="3">
                <span class="gothicA1 fnt0-9 ml-1">공지여부</span>
            </v-col>
            <v-col v-if="bbsCfg.bbsCfCtgryYn == '1'" class="" cols="3">
                <span class="gothicA1 fnt0-9 ml-1">분류</span>
            </v-col>
            <v-col v-if="bbsCfg.bbsCfAnswrYn == '1'" class="pl-2" cols="3">
                <span class="gothicA1 fnt0-9 ml-1">기관명</span>
            </v-col>
            <v-spacer></v-spacer>     
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col v-if="bbsCfg.bbsCfNotiYn == '1'" class="" cols="3">
                <div class="d-flex justify-center pl-2" style="width: 60px; height: 100%;">
                    <span>
                        <v-checkbox
                            v-model="bbsInf.bbsNotiYn"
                            class="checbox-theme opct0-6"
                            true-icon="mdi-bell-ring" false-icon="mdi-bell-off"
                            true-value="1" false-value="2" density="compact"
                            hide-details>
                        </v-checkbox>
                    </span>
                </div>
            </v-col>
            <v-col v-if="bbsCfg.bbsCfCtgryYn == '1'" class="" cols="3">
                <v-select
                    v-model="bbsInf.bbsCtgryCd"
                    class="login-Theme"
                    base-color="grey002" bg-color="white" color="blue103"
                    density="compact" variant="outlined"
                    item-title="valcdnm" item-value="valcd"
                    :items="bbsCfg.codes"  
                    hide-details>               
                </v-select>
            </v-col>
            <v-col v-if="bbsCfg.bbsCfAnswrYn == '1'" class="pl-3 d-flex align-center" cols="3">
                {{ bbsInf.fcltyClnm }}
            </v-col>
            <v-spacer></v-spacer>     
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>
        <v-row v-if="bbsCfg.bbsCfScrtYn == '1'" class="pa-0" no-gutters>
            <v-col  class="" cols="3">
                <span class="gothicA1 fnt0-9 ml-1">비밀글여부</span>
            </v-col>
            <v-col class="pr-2" cols="4">
                <span class="gothicA1 fnt0-9 ml-1">비밀번호</span>
            </v-col>
            <v-col class="pr-2" cols="5">
                <span class="gothicA1 fnt0-9 ml-1">비밀번호 확인</span>
            </v-col>
        </v-row>
        <v-divider v-if="bbsCfg.bbsCfScrtYn == '1'" class="mt-1-qrtr-1" color="white"></v-divider>
        <v-row v-if="bbsCfg.bbsCfScrtYn == '1'" class="pa-0" no-gutters>            
            <v-col class="" cols="3">
                <div class="d-flex justify-center pl-2" style="width: 60px; height: 100%;">
                    <span>
                        <v-checkbox
                            v-model="bbsInf.bbsScrtYn"
                            class="checbox-theme opct0-6"
                            true-icon="mdi-checkbox-marked-circle-outline" false-icon="mdi-checkbox-blank-circle-outline"
                            true-value="1" false-value="2" density="compact"
                            hide-details>
                        </v-checkbox>
                    </span>
                </div>
            </v-col>
            <v-col class="pr-2" cols="4">
                <v-text-field
                    v-model="bbsInf.bbsPasswd"
                    class="login-theme"
                    base-color="grey002" color="blue103"
                    density="compact" variant="outlined"
                    :rules="bbsInf.bbsScrtYn == '1' ? [rules.getRequired] : []"
                    hide-details>
                </v-text-field>
            </v-col>
            <v-col class="pr-2" cols="5">
                <v-text-field
                    v-model="bbsInf.bbsCnfrmPasswd"
                    :append-icon="showCnfrmPwd ? 'mdi-eye' : 'mdi-eye-off'"
                    class="login-theme"
                    base-color="grey002" color="blue103"
                    density="compact" variant="outlined"
                    :rules="[(bbsInf.bbsPasswd === bbsInf.bbsCnfrmPasswd) || '비밀번호가 일치하지 않습니다.']"
                    :type="showCnfrmPwd ? 'text' : 'password'"
                    @click:append="showCnfrmPwd = !showCnfrmPwd"
                    hide-details>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider v-if="bbsCfg.bbsCfScrtYn == '1'" class="my-2" color="white"></v-divider>        
        <v-row class="pa-0" no-gutters>
            <span class="gothicA1 fnt0-9 ml-1">내용</span>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <CmmEdtr v-model="bbsInf.bbsCntntHtml" @plainTxt="rflEdtTxt"></CmmEdtr>
            <!-- <v-textarea
                v-model="bbsInf.cntnt"
                class="txtAreaDft" color="#90CAF9"
                variant="filled" rows="13"
                no-resize hide-details>
            </v-textarea> -->
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>
        <v-row v-if="bbsCfg.bbsCfAnswrYn == '1'" class="pa-0" no-gutters>
            <span class="gothicA1 fnt0-9 ml-1">답변</span>
        </v-row>
        <v-row v-if="bbsCfg.bbsCfAnswrYn == '1'" class="pa-0" no-gutters>            
            <v-textarea
                v-model="bbsInf.bbsAnswr"
                class="vTxtArea-theme" color="blue103" base-color="grey002"
                variant="outlined" density="compact" rows="5"
                no-resize hide-details>
            </v-textarea>
        </v-row>
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <span class="gothicA1 fnt0-9 ml-1">자료첨부</span>
        </v-row>
        <CmmFileMgmt :cmnc="'bbs'" :bzPk="bbsInf.bbsPk" @saveFiles="callBackFiles"></CmmFileMgmt>
    </v-form>
</template>
<script setup>
    import { reactive, onMounted, watch,  ref} from 'vue'
    import { glbDlg, glbRules, notiInf, lgnInf} from "@/state"
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    import CmmEdtr from '@/components/commons/CmmEdtr.vue'
    import CmmFileMgmt from '@/components/commons/CmmFileMgmt.vue'

    const props     = defineProps(['psdObj'])
    const dlgState  = glbDlg()
    const rules     = glbRules()
    const noti      = notiInf()
    const lgn       = lgnInf()
    const router = useRouter()
    const bbsForm   = ref(false)

    let bfrSvnFiles = []

    let bbsCfg = reactive({
        bbsId           : '',
        bbsCfTitle      : '',
        bbsCfType       : '',
        bbsCfTheme      : '',
        bbsCfAuth       : '',
        bbsCfViewType   : '',
        bbsCfEditYn     : '',
        bbsCfTagYn      : '',
        bbsCfScrhYn     : '',
        bbsCfAnswrYn    : '',
        bbsCfCommtYn    : '',
        bbsCfFileYn     : '',
        bbsCfCptchYn    : '',
        bbsCfScrtYn     : '',
        bbsCfNotiYn     : '',
        bbsCfCtgryYn    : '',
        codes           : [],
    })

    let bbsInf = reactive({
        bbsPk:0,
        bbsNotiYn:'2',
        bbsScrtYn:'2',
        bbsTitle:'',
        bbsCntnt:'',
        bbsCntntHtml:'',
        bbsAnswr:'',
        bbsPasswd:'',
        bbsCnfrmPasswd:'',
        bbsCtgryCd:'',
    })

    let showCnfrmPwd = ref(false)

    onMounted(() => {
        setBbsInf(props.psdObj)
    })

    watch(() => props.psdObj, (newValue) => {
        setBbsInf(newValue)
    })

    watch(() => dlgState.getSuccActBtn, (newValue) => {
        if(dlgState.getDlgTyp == '106' && newValue)
            savBbs()
    })

    watch(() => dlgState.getCnclActBtn, (newValue) => {
        if(dlgState.getDlgTyp == '106' && newValue)
            rmBbs(bbsInf.bbsPk)
    })

    function setBbsInf(obj) {

        bbsInf.bbsPk    =   obj.bbsInfo.bbsPk
        bbsInf.bbsNotiYn    =   obj.bbsInfo.bbsNotiYn
        bbsInf.bbsScrtYn    =   obj.bbsInfo.bbsScrtYn
        bbsInf.bbsTitle     =   obj.bbsInfo.bbsTitle 
        bbsInf.bbsCntnt     =   obj.bbsInfo.bbsCntnt 
        bbsInf.bbsCntntHtml =   obj.bbsInfo.bbsCntntHtml         
        bbsInf.bbsAnswr     =   obj.bbsInfo.bbsAnswr 
        bbsInf.bbsCtgryCd   =   obj.bbsInfo.bbsCtgryCd
        bbsInf.fcltyClnm    =   obj.bbsInfo.fcltyClnm
        bbsInf.bbsId        =   obj.bbsCfg.bbsId

        bbsCfg.bbsId            = obj.bbsCfg.bbsId
        bbsCfg.bbsCfTitle       = obj.bbsCfg.bbsCfTitle
        bbsCfg.bbsCfType        = obj.bbsCfg.bbsCfType
        bbsCfg.bbsCfTheme       = obj.bbsCfg.bbsCfTheme
        bbsCfg.bbsCfAuth        = obj.bbsCfg.bbsCfAuth
        bbsCfg.bbsCfViewType    = obj.bbsCfg.bbsCfViewType
        bbsCfg.bbsCfEditYn      = obj.bbsCfg.bbsCfEditYn
        bbsCfg.bbsCfTagYn       = obj.bbsCfg.bbsCfTagYn
        bbsCfg.bbsCfScrhYn      = obj.bbsCfg.bbsCfScrhYn
        bbsCfg.bbsCfAnswrYn     = obj.bbsCfg.bbsCfAnswrYn
        bbsCfg.bbsCfCommtYn     = obj.bbsCfg.bbsCfCommtYn
        bbsCfg.bbsCfFileYn      = obj.bbsCfg.bbsCfFileYn
        bbsCfg.bbsCfCptchYn     = obj.bbsCfg.bbsCfCptchYn
        bbsCfg.bbsCfScrtYn      = obj.bbsCfg.bbsCfScrtYn
        bbsCfg.bbsCfNotiYn      = obj.bbsCfg.bbsCfNotiYn
        bbsCfg.bbsCfCtgryYn     = obj.bbsCfg.bbsCfCtgryYn

        bbsCfg.codes.splice(0)

        if(obj.bbsCfg.bbsCfCtgryYn == '1') {
            if(obj.bbsCfg.codes != null){
                bbsCfg.codes.push({valcd:'',valcdnm:'분류',valclcd:'',valclcdnm:'분류'})
                obj.bbsCfg.codes.forEach(el => {
                    bbsCfg.codes.push(el)
                });
            }
        }
    }

    function rflEdtTxt(txt) {
        bbsInf.bbsCntnt = txt
    }

    function callBackFiles(arr) {
        bfrSvnFiles = arr
        console.log(bfrSvnFiles)
    }

    function savBbs(){
        if(bbsForm.value){
            let obj = Object.assign({}, bbsInf)  
            obj.fcltyNum = '10000000000'
            obj.files = []
            
            if(Array.isArray(bfrSvnFiles)){
                bfrSvnFiles.forEach(idx => {
                    if(idx.atchdFilePk == undefined)
                        obj.files.push(idx)
                });
            }
        
            setBbs(obj)
        }
        else{
            let msg = '미입력 데이터 또는 규칙에 맞지않는 데이터를 확인해주세요.'
            noti.$patch({ stt: true, ttl: 'Warning', cntnt: msg, type: 'warning', tmOut: 2500 })
            dlgState.$patch({ succActBtn: false })
        }
    }

    // YYS 게시판 저장
    async function setBbs(obj) {
        
        if(obj.bbsNotiYn != '1') obj.bbsNotiYn = '2'

        await cmsApi.post('bbs/insBbs', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    dlgState.$patch({ isDlg: false, succActBtn: false })
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // YYS 게시판 삭제
    async function rmBbs(bbsPk) {
        let obj = {}
        obj.bbsPk = bbsPk

        await cmsApi.post('bbs/delBbs', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    dlgState.$patch({ isDlg: false, cnclActBtn: false })
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

</script>