import { Extension } from '@tiptap/vue-3'
import { Node, Mark, mergeAttributes, wrappingInputRule } from '@tiptap/core'

// 폰트 크기 변경 커스텀 이벤트 작성
const chgFntSz = Extension.create({
    name: 'fontSize',
    addOptions() {
        return {
            types: ['textStyle'],
        };
    },
    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontSize: {
                        default: null,
                        parseHTML: element => element.style.fontSize.replace(/['"]+/g, ''),
                        renderHTML: attributes => {
                            if (!attributes.fontSize) {
                                return {};
                            }
                            return {
                                style: `font-size: ${attributes.fontSize}`,
                            };
                        },
                    },
                },
            },
        ];
    },
    addCommands() {
        return {
            setFontSize: fontSize => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { fontSize: fontSize + "px" })
                    .run();
            },
            unsetFontSize: () => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { fontSize: null })
                    .removeEmptyTextStyle()
                    .run();
            },
        };
    },
})

// 알림 박스 커스텀 객체 생성
const AlertBox = Node.create({

    name: 'AlertBox',
    content: 'block+',
    group: 'block',
    defining: true,
    priority: 1000,

    parseHTML() {
        return [
            { tag: 'AlertBox' },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['AlertBox', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addCommands() {
        return {
            setAlertBox: () => ({ commands }) => {
                return commands.wrapIn(this.name)
            },
            toggleAlertBox: () => ({ commands }) => {
                return commands.toggleWrap(this.name)
            },
            unsetAlertBox: () => ({ commands }) => {
                return commands.lift(this.name)
            },
        };
    },

    addOptions() {
        return {
            HTMLAttributes: { class: 'alertBox-Theme' },
        }
    },

    addInputRules() {
        return [
            wrappingInputRule({
                find: /^\s*>\s$/,
                type: this.type,
            }),
        ]
    },
})

// 아이콘 삽입
const addIcon = Node.create({
    name: 'addIcon',

    addOptions() {
        return {
            inline: false,
            HTMLAttributes: {},
        }
    },

    inline() {
        return this.options.inline
    },
    
    group() {
        return this.options.inline ? 'inline' : 'block'
    },

    addAttributes() {
        return {
            class: {
                default: 'edtMdi',
                renderHTML: attributes => {
                    return {
                        class: attributes.class,
                    }
                },
            },
        }
    },

    parseHTML() {
        return [
            { tag: 'span' },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
    },

    addCommands() {
        return {
            setIcon: options => ({ commands }) => {
                // let arr = this.options.HTMLAttributes.class.split(' ')
                // if(arr.length > 0)
                //     this.options.HTMLAttributes.class = arr[0] + ' ' + options

                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                })
            },
        }
    },
    
})

// 책갈피 생성
const bookMark = Mark.create({

    name: 'bookMark',
  
    priority: 1000,
  
    keepOnSplit: false,
  
    addAttributes() {
        return {
            id: {
                default: null,
            },
        }
    },
  
    parseHTML() {
        return [{ tag: 'span[id]:not([id *= "javascript:" i])' }]
    },
  
    renderHTML({ HTMLAttributes }) {
        if (HTMLAttributes.id?.startsWith('javascript:')) {
            return ['span', mergeAttributes(this.options.HTMLAttributes, { ...HTMLAttributes, id: '' }), 0]
        }
        return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },
  
    addCommands() {
        return {
            setBookMark:
                attributes => ({ chain }) => {
                    return chain().setMark(this.name, attributes).run()
                },
        }
    },
    
})

export { AlertBox, chgFntSz, addIcon, bookMark }