<template>
    <v-form v-model="bbsForm">        
        <v-row no-gutters>
            <v-col cols="12">                
                <v-textarea
                    v-model="bbsCmntsItm.bbsCmntsCntnt"
                    class="vTxtArea-theme" color="blue103" base-color="grey002"
                    variant="outlined" density="compact" rows="2"
                    :rules="[rules.getRequired]"
                    no-resize hide-details>
                </v-textarea>
            </v-col>
        </v-row>
        <v-row class="mt-2" no-gutters>
            <v-spacer></v-spacer> 
            <v-col class="d-flex justify-end" cols="2">
                <v-btn        
                    class="grdt-btn-blue001 text-white"
                    v-bind="props"
                    width="80" height="34" variant="elevated"
                    @click="savCmntsBbs"
                >
                    <span style="font-size: 1rem;">저장</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-3" no-gutters>
            <v-data-table-virtual
                    class="tbl-theme rounded" density="compact" color="red"
                    :headers="tblUse.header"
                    :items="tblUse.serverItems"
                    :loading="tblUse.loading"
                    item-value="name"
                    @update:options="selBbsList"
                    fixed-header>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="txtAlgnMid">{{ item.bbsCmntsCntnt }}</td>                            
                            <td class="txtAlgnMid">{{ item.crtr }}</td>
                            <td class="txtAlgnMid">{{ item.crtDt }}</td>
                            <td class="txtAlgnMid">
                                
                                <v-btn        
                                    v-if="!tblUse.loading"
                                    variant="plain"
                                    density="compact"
                                    class="mr-2"
                                    icon   
                                    @click="rmBbsCmnt(item)"
                                >
                                    <v-icon size="20">mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table-virtual>
        </v-row>
    </v-form>
</template>
<script setup>
    import { reactive, onMounted, watch,  ref} from 'vue'    
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'
    import { glbDlg, glbRules, lgnInf, notiInf } from "@/state"

    const props     = defineProps(['psdObj'])
    const bbsForm   = ref(false)
    const router    = useRouter()
    const lgn       = lgnInf()
    const noti      = notiInf()
    const rules     = glbRules()
    const dlgState  = glbDlg()

    let bbsCfg = reactive({
        bbsId           : '',
        bbsCfTitle      : '',
        bbsCfType       : '',
        bbsCfTheme      : '',
        bbsCfAuth       : '',
        bbsCfViewType   : '',
        bbsCfEditYn     : '',
        bbsCfTagYn      : '',
        bbsCfScrhYn     : '',
        bbsCfAnswrYn    : '',
        bbsCfCommtYn    : '',
        bbsCfFileYn     : '',
        bbsCfCptchYn    : '',
        bbsCfScrtYn     : '',
        bbsCfNotiYn     : '',
        bbsCfCtgryYn    : '',
        codes           : [],
    })

    let bbsInf = reactive({
        bbsPk:0,
        bbsNotiYn:'2',
        bbsScrtYn:'2',
        bbsTitle:'',
        bbsCntnt:'',
        bbsCntntHtml:'',
        bbsAnswr:'',
        bbsPasswd:'',
        bbsCnfrmPasswd:'',
        bbsCtgryCd:'',
    })

    let bbsCmntsItm = reactive({
        bbsPk:0,
        bbsCmntsCntnt:'',
    })

    let tblUse = reactive({
        loading: true,
        totalItems: 0,
        serverItems: [],
        header:[
            { title: '내용', key: 'bbsCmntsCntnt', sortable: false, align: 'center', width: '400' },
            { title: '작성자', key: 'crtr', sortable: false, align: 'center', minWidth: '100' },
            { title: '작성일', key: 'crtDt', sortable: false, align: 'center', minWidth: '150' },            
            { title: '처리', key: 'actions', sortable: false, align: 'center', width: '100' },
        ],
    })

    onMounted(() => {
        setBbsInf(props.psdObj)
    })

    watch(() => props.psdObj, (newValue) => {
        setBbsInf(newValue)
    })


    function setBbsInf(obj) {
        bbsInf.bbsPk    =   obj.bbsInfo.bbsPk
        bbsInf.bbsNotiYn    =   obj.bbsInfo.bbsNotiYn
        bbsInf.bbsScrtYn    =   obj.bbsInfo.bbsScrtYn
        bbsInf.bbsTitle     =   obj.bbsInfo.bbsTitle 
        bbsInf.bbsCntnt     =   obj.bbsInfo.bbsCntnt 
        bbsInf.bbsCntntHtml =   obj.bbsInfo.bbsCntntHtml         
        bbsInf.bbsAnswr     =   obj.bbsInfo.bbsAnswr 
        bbsInf.bbsCtgryCd   =   obj.bbsInfo.bbsCtgryCd


        bbsCfg.bbsCfTitle      = obj.bbsCfg.bbsCfTitle
        bbsCfg.bbsCfType       = obj.bbsCfg.bbsCfType
        bbsCfg.bbsCfTheme      = obj.bbsCfg.bbsCfTheme
        bbsCfg.bbsCfAuth       = obj.bbsCfg.bbsCfAuth
        bbsCfg.bbsCfViewType   = obj.bbsCfg.bbsCfViewType
        bbsCfg.bbsCfEditYn     = obj.bbsCfg.bbsCfEditYn
        bbsCfg.bbsCfTagYn      = obj.bbsCfg.bbsCfTagYn
        bbsCfg.bbsCfScrhYn     = obj.bbsCfg.bbsCfScrhYn
        bbsCfg.bbsCfAnswrYn    = obj.bbsCfg.bbsCfAnswrYn
        bbsCfg.bbsCfCommtYn    = obj.bbsCfg.bbsCfCommtYn
        bbsCfg.bbsCfFileYn     = obj.bbsCfg.bbsCfFileYn
        bbsCfg.bbsCfCptchYn    = obj.bbsCfg.bbsCfCptchYn
        bbsCfg.bbsCfScrtYn     = obj.bbsCfg.bbsCfScrtYn
        bbsCfg.bbsCfNotiYn     = obj.bbsCfg.bbsCfNotiYn
        bbsCfg.bbsCfCtgryYn    = obj.bbsCfg.bbsCfCtgryYn

        bbsCfg.codes.splice(0)

        if(obj.bbsCfg.bbsCfCtgryYn == '1') {
            if(obj.bbsCfg.codes != null){
                bbsCfg.codes.push({valcd:'',valcdnm:'분류',valclcd:'',valclcdnm:'분류'})
                obj.bbsCfg.codes.forEach(el => {
                    bbsCfg.codes.push(el)
                });
            }
        }
        selBbsCmntList()
    }

    async function selBbsCmntList(){
        if(bbsInf.bbsPk != ''){
            tblUse.serverItems.splice(0)
            tblUse.loading = true
            
            await cmsApi.post('bbs/selBbsCmntsList', {bbsPk:bbsInf.bbsPk})
                .then((response) => {
                    
                    if(response.data.statusCode == '200'){   
                        tblUse.loading = false 
                        response.data.result.forEach(item => {                                
                            tblUse.serverItems.push(item)
                        });
                    }
                    else{
                        if(response.data.statusCode == '610'){
                            lgn.$patch({
                                account: '',
                                name: '',
                                level: '',
                                token: '',
                                key: '',
                            })
                            noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
        
                            router.push({ name: 'LoginComp' })
                        }
                        else
                            noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                    }
                })
                .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
        }
    }
    
    //댓글저장
    function savCmntsBbs(){
        if(bbsForm.value){
            let obj = {
                fcltyNum:'10000000000',
                bbsPk:bbsInf.bbsPk,                    
                bbsCmntsCntnt:bbsCmntsItm.bbsCmntsCntnt,                
            }

            console.log(obj)
            setCmntsBbs(obj)
        }
        else{
            let msg = '미입력 데이터 또는 규칙에 맞지않는 데이터를 확인해주세요.'
            noti.$patch({ stt: true, ttl: 'Warning', cntnt: msg, type: 'warning', tmOut: 2500 })
            dlgState.$patch({ succActBtn: false })
        }
    }

    // 댓글저장
    async function setCmntsBbs(obj) {
        await cmsApi.post('bbs/insBbsCmnts', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    selBbsCmntList()
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // YYS 게시판 댓글 삭제
    async function rmBbsCmnt(itm) {        

        await cmsApi.post('bbs/delBbsCmnts', {bbsCmntsPk:itm.bbsCmntsPk})
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    selBbsCmntList()
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }
</script>