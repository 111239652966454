<template>
    <draggable
        v-bind="dragOptions"
        tag="div"
        :list="props.modelValue"
        itemKey="mnlCtgryPk"
        :value="props.modelValue"
        @start="draggObj.drag = true"
        @end="draggObj.drag = false"
        @change="emitter">
        <template #item="{ element, index }">
            <div :class="['crsShp-Pointer', typeof element.listDtl != 'undefined' ? 'group-g1' : 'group-g2']">
                <div :class="[typeof element.listDtl != 'undefined' ? 'item-g1 rounded-t' : 'item-g2']">
                    <v-row class="pa-0 align-center" no-gutters>
                        <span v-if="typeof element.listDtl == 'undefined'" class="mr-3 pt-1">└</span>
                        <v-text-field
                            v-model="element.mnlCtgryNm"
                            class="login-theme"
                            base-color="grey002" color="blue103"
                            density="compact" variant="outlined"
                            append-inner-icon="mdi-close-circle-outline"
                            :append-icon="typeof element.listDtl == 'undefined' ? '' : 'mdi-plus-circle-outline'"
                            :prepend-inner-icon="typeof element.listDtl == 'undefined' ? '' : 'mdi-chevron-right'"
                            :rules="[rules.getRequired]"
                            @click:appendInner="rmItm(element, index)" @click:append="addDtl(index)"
                            hide-details>
                        </v-text-field>
                    </v-row>
                </div>
                <CmmClsfcDraggerble class="item-sub" v-model="element.listDtl" :cat="false" @rmItem="thwEvt" />
            </div>
        </template>
    </draggable>
</template>

<script setup>
    import draggable from 'vue3-draggable-next';
    import { onMounted, watch, reactive, computed } from 'vue';
    import { glbRules } from "@/state"

    const props = defineProps(['modelValue', 'cat'])
    const emit = defineEmits(['update:modelValue', 'rmItem'])
    const rules = glbRules()
    const dragOptions = computed(function(){
        return {
            animation: 0,
            group: props.cat ? 'g1' : 'g2',
            disabled: false,
            ghostClass: 'ghost'
        }
    });
    // const realValue = computed(function(){
    //     return props.modelValue ? props.modelValue : props.list
    // });

    let draggObj = reactive({
        orgList: [],
        isDrag: false,
    })

    onMounted(() => {
        //console.log(props.modelValue)
    })

    watch(() => props.modelValue, (currentValue) => {
        draggObj.orgList = currentValue    
    },{ deep: true })

    function emitter() {
        //console.log(v)
        emit('update:modelValue', props.modelValue)
    }

    function rmItm(obj, idx) {
        if(props.cat){
            if(obj.mnlCtgryPk == 0){
                if(props.modelValue[idx].listDtl.length > 0)
                    emit('rmItem', obj, idx)
                else{
                    let tmp = Object.assign([], props.modelValue)
                    tmp.splice(idx, 1)
                    emit('update:modelValue', tmp)
                }
            }
            else
                emit('rmItem', obj, 'depth1', idx)
        }
        else{
            if(obj.mnlCtgryPk == 0){
                let tmp = Object.assign([], props.modelValue)
                tmp.splice(idx, 1)
                emit('update:modelValue', tmp)
            }
            else
                emit('rmItem', obj, idx)
        }
    }

    function thwEvt(obj, idx) {
        emit('rmItem', obj, 'depth2', idx)
    }

    function addDtl(idx){
        let obj = {}
        obj.mnlCtgryNm = ''
        obj.mnlCtgryPk = 0

        let tmp = Object.assign([], props.modelValue)
        tmp[idx].listDtl.push(obj)

        emit('update:modelValue', tmp)
    }

</script>

<style scoped>
    .group-g1 {
        margin-bottom: 4px; border-radius: 4px !important;
        border: 1px solid #d1d6e0;
    }
    .group-g1 > div:first-child {
        padding: 8px 20px 12px 20px; background-color: #f9fbfd !important; font-weight: 500;
    }
    .group-g2 > div:first-child {
        border-top: 1px solid #d1d6e0; padding: 4px 59px 10px 30px; background-color: white;
        border-bottom-left-radius: 4px; border-bottom-right-radius: 4px;
    }
</style>