import { defineStore } from 'pinia'

let clntInf = defineStore('clntInf', {
    state: () => ({ ip: '', loc: '', zipCd: '', country: '', region: '', city: '', timezone: '' }),

    getters: {
        getClntIp: (state) => state.ip,
        getClntLoc: (state) => state.loc,
        getClntZipCd: (state) => state.zipCd,
        getClntCountry: (state) => state.country,
        getClntRegion: (state) => state.region,
        getClntCity: (state) => state.city,
        getClntTimeZone: (state) => state.timezone
    },
    
    actions: {
        setClntInf(obj) {
            this.ip = obj.ip
            this.loc = obj.loc
            this.zipCd = obj.postal
            this.country = obj.country
            this.region = obj.region
            this.city = obj.city
            this.timezone = obj.timezone
        },
    },

    persist: {
        enabled: true,
        storage: sessionStorage
    },
})

let lgnInf = defineStore('lgnInf', {
    state: () => ({ account: '', name: '', level: '', token: '', key: '' }),

    getters: {
        getLgnAccount: (state) => state.account,
        getLgnNm: (state) => state.name,
        getLgnlv: (state) => state.level,
        getLgnToken: (state) => state.token,
        getLgnKey: (state) => state.key,
    },
    
    actions: {

    },

    persist: {
        enabled: true,
        storage: sessionStorage
    },
})

let notiInf = defineStore('notiInf', {
    state: () => ({ stt: false, ttl:'Info', cntnt: 'information', type: 'info', tmOut: 3000, }),

    getters: {
        getNotiStt: (state) => state.stt,
        getNotiTtl: (state) => state.ttl,
        getNotiCntnt: (state) => state.cntnt,
        getNotiTyp: (state) => state.type,
        getNotiTmOut: (state) => state.tmOut,
    },
    
    actions: {

    },
})

let displayInfStore = defineStore('displayInf', {
    state: () => ({ width: 0, height: 0, isMobile: false, breakPoint: 'lg', }),

    getters: {
        doubleCount: (state) => state.count * 2,
    },
    
    actions: {

    },
})

let glbDlg = defineStore('glbDlg', {
    // typ code guide - 102 ( 1: 시스템 분류, 02: 모달 순번, ex: 101 = YYS에 첫번째 모달 )
    state: () => ({
        isDlg: false, width: '100', typ:'', title: '', psdObj: null,
        onCnclBtn: true, onSuccBtn: true,
        cnclActBtn: false, succActBtn: false,
    }),

    getters: {
        getDlgStt: (state) => state.isDlg,
        getDlgWth: (state) => state.width,
        getDlgTyp: (state) => state.typ,
        getDlgTtl: (state) => state.title,
        getPsdObj: (state) => state.psdObj,
        getCnclBtn: (state) => state.onCnclBtn,
        getSuccBtn: (state) => state.onSuccBtn,
        getCnclActBtn: (state) => state.cnclActBtn,
        getSuccActBtn: (state) => state.succActBtn,
    },
    
    actions: {
        clickCncl() {
            this.cnclActBtn = true

            setTimeout(function(){
                this.cnclActBtn = false
            }, 1000)
        },
        clickSucc() {
            this.succActBtn = true

            setTimeout(function(){
                this.succActBtn = false
            }, 1000)
        }
    },
})

let glbRules = defineStore('glbRules', {
    state: () => ({
        required: value => !!value || 'Field is required',
    }),

    getters: {
        getRequired: (state) => state.required,
    },
    
    actions: {

    },
})

export{ clntInf, lgnInf, notiInf, displayInfStore, glbDlg, glbRules }