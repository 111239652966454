<template>
    <v-sheet>
        <v-row class="pa-0" no-gutters>
            <v-col cols="12">
                <v-file-input
                    v-model="upFiles" :show-size="1000"
                    class="v-file-outlined-Theme" color="blue103"
                    placeholder="Upload your documents"
                    prepend-inner-icon="mdi-upload-box-outline" prepend-icon=""
                    density="compact" variant="outlined"
                    @click:clear="rmFiles" @update:modelValue="rflFileInp"
                    multiple counter>
                    <template v-slot:selection="{ fileNames }">
                        <template v-for="(fileName, index) in fileNames" :key="fileName">
                            <v-chip
                                v-if="index < 1"
                                class="" color="indigo-accent-4" size="small"
                                label>
                                {{ fileName }}
                            </v-chip>
            
                            <span
                                v-else-if="index === 1"
                                class="text-overline text-grey-darken-3 mx-2">
                                + {{ upFiles.length - 1 }} File(s)
                            </span>
                        </template>
                    </template>
                </v-file-input>
            </v-col>
        </v-row>
        <v-divider class="my-1" color="white" vertical></v-divider>
        <v-row class="pa-0" no-gutters>
            <span
                v-for="files in fileList" :key="files"
                class="d-flex align-center rounded srndBrdr-grey002 px-2 pb-1 mx-1 mb-1">
                <v-icon v-if="files.isDB == '1'" class="mt-1 opct0-6" color="blue102" size="20">mdi-folder-check</v-icon>
                <v-icon v-else class="opct0-6" style="margin-top: 6px;" color="blue102" size="20">mdi-folder-arrow-up</v-icon>
                <span
                    class="d-inline-block crsShp-Pointer overflow-text-hidden px-2"
                    style="max-width: 150px; height: auto;"
                    @click="fileDown(files)">
                    <span v-if="props.cmnc == 'yys'" class="fnt0-8">{{ files.orgFileNm }}</span>
                    <span v-else-if="props.cmnc == 'bbs'" class="fnt0-8">{{ files.bbsFileOrgNm }}</span>
                </span>
                <span
                    v-if=" props.cmnc == 'yys' ? typeof files.atchdFilePk == 'undefined' : typeof files.bbsFilePk == 'undefined'"
                    class="d-inline-block" style="width: 1px; height: 28px; margin-top: 6px;">
                </span>
                <v-btn
                    v-else
                    class="" style="margin-top: 6px;"
                    density="compact" variant="flat"
                    @click="rmAtchdFile(files.atchdFilePk, files.bbsFilePk)" icon>
                    <v-icon class="opct0-6" color="blue102" size="20">mdi-close-box</v-icon>
                </v-btn>
            </span>
        </v-row>
    </v-sheet>
</template>

<script setup>
    import { onMounted, watch, ref } from 'vue'

    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    import { lgnInf, notiInf } from "@/state"

    const props = defineProps(['cmnc', 'bzPk'])
    const emit = defineEmits(['saveFiles'])

    const router = useRouter()
    const lgn = lgnInf()
    const noti = notiInf()
    
    let upFiles = ref(null)
    let fileList = ref([])
 
    onMounted(() => {

    })

    watch(() => upFiles.value, (newValue) => {
        let reg = /(.*?)\.(hwp|doc|docx|xls|xlsx|ppt|pptx|pdf|txt|jpg|jpeg|png|gif|bmp|tiff|tif|zip|rar|alz|egg|7z|mp4|avi|mkv)$/
        let formData = new FormData()

        if(newValue != null){
            newValue.forEach(idx => {
                if(idx.name.match(reg))
                    formData.append('files', idx)
            })
            
            let cnt = 0
            for (let key of formData.keys()) {
                if(key == 'files')
                    cnt += 1
            }

            if(cnt > 0 && props.cmnc == 'yys')
                yysUpload(formData)
            else if(cnt > 0 && props.cmnc == 'bbs')
                bbsUpload(formData)
        }
    })

    watch(() => props.bzPk, (newValue) => {
        if(props.cmnc == 'yys')
            getNtcFiles(newValue)
        else if(props.cmnc == 'bbs')
            getBbsFiles(newValue)
    })

    // 파일 목록 조회 (yys)
    async function getNtcFiles(ntcPk) {
        let obj = {}
        obj.bzPk = ntcPk
        obj.bzClcd = '24'
        obj.fcltyNum = '10000000000'

        await cmsApi.post('opr/selAtchdFileList', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    let files = []
                    response.data.result.forEach(idx => {
                        let obj = {}
                        obj.atchdFilePk = idx.atchdFilePk
                        obj.filePth = idx.filePth
                        obj.fileNm = idx.fileNm
                        obj.orgFileNm = idx.orgFileNm
                        obj.isDB = '1'

                        files.push(obj)
                    });
                    fileList.value = files
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // 파일 목록 조회 (bbs)
    async function getBbsFiles(bbsPk) {
        let obj = {}
        obj.bbsPk = bbsPk
  
        await cmsApi.post('bbs/selBbsFileList', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    response.data.result.forEach(idx => {
                        idx.isDB = '1'
                    });
                    fileList.value = response.data.result
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // 파일 업로드 (yys)
    async function yysUpload(frm) {
        await cmsApi.post('cmm/tmpFileUpload', frm)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    response.data.files.forEach(idx => {
                        idx.isDB = '2'
                    });

                    rmFiles()
                    response.data.files.forEach(idx => {
                        fileList.value.push(idx)
                    });
                    emit('saveFiles', response.data.files)
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // 파일 업로드 (bbs)
    async function bbsUpload(frm) {
        await cmsApi.post('cmm/tmpBbsFileUpload', frm)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    response.data.files.forEach(idx => {
                        idx.isDB = '2'
                    });
                    rmFiles()
                    response.data.files.forEach(idx => {
                        fileList.value.push(idx)
                    });
                    emit('saveFiles', response.data.files)
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // 파일 삭제 yys
    async function rmNtcFile(atcdPk) {
        let obj = {}
        obj.fcltyNum = '10000000000'
        obj.atchdFilePk = atcdPk

        await cmsApi.post('opr/delAtchdFile', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    let chc = fileList.value.findIndex( v => v.atchdFilePk == atcdPk )
                    if(chc > -1)
                        fileList.value.splice(chc, 1)

                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // 파일 삭제 bbs
    async function rmBbsFile(atcdPk) {
        let obj = {}
        obj.bbsFilePk = atcdPk

        await cmsApi.post('bbs/delBbsFile', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    let chc = fileList.value.findIndex( v => v.bbsFilePk == atcdPk )
                    if(chc > -1)
                        fileList.value.splice(chc, 1)

                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // 파일 다운로드
    async function fileDown(obj){
        let uri = ''
        if(props.cmnc == 'yys')
            uri = 'cmm/fileDownload'
        else if(props.cmnc == 'bbs')
            uri = 'bbs/bbsFileDownload'

        await cmsApi({
            method: 'post',
            url: uri,
            responseType: 'arraybuffer',
            data: obj
        }).then((response) => {
            createFile(response)
        })
        .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }
    
    function createFile(res){
        let contentDisposition = decodeURI(res.headers['content-disposition'])
       
        let link = document.createElement('a')
        let url = window.URL.createObjectURL(new Blob([res.data]))
        let fileName = 'unknown'

        if (contentDisposition) {
            const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
            if (fileNameMatch)
                [ , fileName ] = fileNameMatch.split('=')
        }
        link.href = url

        fileName = fileName.replaceAll('"', '')
        link.setAttribute('download', `${fileName}`)
        link.style.cssText = 'display:none'
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    function rmAtchdFile(atchdPk, bbsPk) {
        if(props.cmnc == 'yys')
            rmNtcFile(atchdPk)
        else if(props.cmnc == 'bbs')
            rmBbsFile(bbsPk)
    }

    function rmFiles() {
        fileList.value.forEach((idx, i) => {
            if(idx.isDB == '2')
                fileList.value.splice(i, 1)
        });
    }

    function rflFileInp() {
        if(upFiles.value.length == 0)
            rmFiles()
    }

</script>