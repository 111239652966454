import axios from 'axios';
import { lgnInf } from "@/state";

const devUrl = 'http://172.30.1.11:4400/'
//개발 서버 https://yoyangsys.com:8090/
//운영 서버 https://cmsapi.yoyangsys.co.kr/
//ncloud 서버 https://cmsapi.yoyangsys.co.kr/
const prodUrl = 'https://yoyangsys.com:8090/'

let remoteURL = ''
let remotePORT = 80
let remotePROTOCOL = 'http'

if (process.env.NODE_ENV == 'development') {
    remoteURL = devUrl
    remotePORT = 80
    remotePROTOCOL = 'http'
}
else if (process.env.NODE_ENV == 'production'){
    remoteURL = prodUrl
    remotePORT = 8089
    remotePROTOCOL = 'https'
}

const cmsApi = axios.create({
    baseURL: remoteURL,
    timeout: 0,
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin': 'harrygnd.co.kr',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Credentials': 'true',
    },
    proxy: {
        protocol: remotePROTOCOL,
        host: 'harrygnd.co.kr',
        port: remotePORT,
    },
})

cmsApi.interceptors.request.use(
    (config) => {
        if(lgnInf().getLgnToken != ''){
            config.headers.token = lgnInf().getLgnToken
            config.headers.key = lgnInf().getLgnKey
        }
        return config;
    },
    (error) => {
        // 요청 에러 직전 호출됩니다.
        return Promise.reject(error)
    }
);

cmsApi.interceptors.response.use(
    (config) => {
        //console.log(config)
        return config;
    },
    (error) => {
        // 요청 에러 직전 호출됩니다.
        return Promise.reject(error)
    }
);

export{
    cmsApi, remoteURL
}
