import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { loadFonts } from './plugins/webfontloader'
import { createI18n } from 'vue-i18n'
import ko from './lang/ko.json'
import en from './lang/en.json'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import moment from 'moment'
import 'moment/locale/ko'

// 웹 폰트 로더 적용
loadFonts()

// 다국어 설정
const i18n = createI18n({
    legacy: false,
    locale: 'ko',
    fallbackLocale: 'en',
    messages: {
      en: en,
      ko: ko
    }
})

// 뷰 및 전역 객체 설정
const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.config.globalProperties.$moment = moment

app.use(router)
  .use(vuetify)
  .use(pinia)
  .use(i18n)
  .provide('$axios', axios)
  .mount('#app')
