<template>
    <span class="d-inline-block" style="width: 160px;">
        <v-text-field
            class="login-theme" variant="outlined" density="compact"
            base-color="grey002" color="blue103"
            :disabled="disabled" :label="label" v-model="sltDt"
            :rules="required ? [mmChk] : (sltDt != '' ? [mmChk] : [])"
            @update:modelValue="lvData"
            hide-details>
            <template v-slot:append-inner>
                <v-menu v-model="isPicker" :close-on-content-click="false">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            v-bind="props" variant="plain" size="30"
                                icon slim>
                            <v-icon size="26">mdi-calendar-range</v-icon>
                        </v-btn>
                    </template>
                    <v-date-picker
                        class="CmmDtPckr" color="primary" width="328" elevation="6"
                        @update:modelValue="rflDtPckr"
                        hide-header>
                    </v-date-picker>
                </v-menu>
            </template>
        </v-text-field>
    </span>
</template>

<script setup>
    import { onMounted, ref, watch, getCurrentInstance } from 'vue'
    
    const emit = defineEmits(['update:modelValue'])
    const props = defineProps({
        modelValue: { type: String, required: false },
        minDt: { type: String, required: false },
        maxDt: { type: String, required: false },
        value: { type: String, required: false },
        label: { type: String, required: false },
        required: { type: Boolean, required: false },
        btnActv: { type: Boolean, required: false },
        succBck: { type: Boolean, required: false },
        disabled: { type: Boolean, required: false },
    })
    const $moment = getCurrentInstance().appContext.config.globalProperties.$moment

    let minDt = ref( props.minDt || '' )
    let maxDt = ref( props.maxDt || '' )
    //let value = ref( props.maxDt || '' )
    let label = ref( props.label || '' )
    let required = ref( props.required || false )
    //let btnActv = ref( props.btnActv || true )
    let succBck = ref( props.succBck || false )
    let disabled = ref( props.disabled || false )

    let sltDt = ref(props.modelValue || '')
    let isPicker = ref(false)
    // let rules = ref(
    //     { brthCnfr: value => /^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(value) || 'different' }
    // )

    onMounted(() => {
      
    })

    watch(() => props.modelValue, (newValue) => {
        if(newValue != '')
            sltDt.value = newValue
    })

    watch(() => props.minDt, (newValue) => {
        if(newValue == 'undefined')
            minDt.value = ''
        else
            minDt.value = newValue
    })

    watch(() => props.maxDt, (newValue) => {
        if(newValue == 'undefined')
            maxDt.value = ''
        else
            maxDt.value = newValue
    })

    function mmChk () {
        if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(sltDt.value)){
            if(minDt.value != ''){
                if(new Date(minDt.value) <= new Date(sltDt.value))
                    return true
                else
                    return false
            }
         
            if(maxDt.value != ''){
                if(new Date(maxDt.value) >= new Date(sltDt.value))
                    return true
                else
                    return false
            }

            if(maxDt.value  == '' && maxDt.value  == '')
                return true
                
        }
        else
            return false
    }

    function lvData (v) {
        if(succBck.value)
            evtChgDtFrm(v)
        else
            chnDtFrm(v)
    }

    function vmlRflt (v) {
        sltDt.value = v
        emit('update:modelValue', v)
    }

    function evtChgDtFrm (v) {
        let rst = ''
        
        if(v != null)
            rst = v.replace(/\s/gi, "")

        if(rst.length == 8)
            rst = rst.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
        
        if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(rst)){
            sltDt.value = rst
            emit('update:modelValue', rst)
        }
        else{
            sltDt.value = rst.replaceAll('-', '')
        }
    }

    function chnDtFrm (v) {
        let rst = ''
        
        if(v != null)
            rst = v.replace(/\s/gi, "")

        if(rst.length == 8)
            rst = rst.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
        
        if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(rst)){
            vmlRflt(rst)
        }
        else{
            vmlRflt(rst.replaceAll('-', ''))
        }
    }

    function rflDtPckr (obj) {
        let dt = $moment(obj).format('YYYY-MM-DD')
        lvData(dt)
        isPicker.value = false
    }

</script>