<template>
    <v-form v-model="mnlForm">
        <v-row class="pa-0" no-gutters>
            <span class="gothicA1 fnt0-9 ml-1">제목</span>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-text-field
                v-model="mnlInf.mnlTitle"
                class="login-theme"
                base-color="grey002" color="blue103"
                density="compact" variant="outlined"
                :rules="[rules.getRequired]"
                hide-details>
            </v-text-field>
        </v-row>   
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col cols="4">
                <span class="gothicA1 fnt0-9 ml-1">1차 분류</span>
            </v-col>
            <v-col class="pl-4" cols="4">
                <span class="gothicA1 fnt0-9 ml-1">2차 분류</span>
            </v-col>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col cols="4">
                <v-select
                    v-model="mnlInf.upMnlCtgryPk"
                    class="slt-Theme"
                    base-color="grey002" bg-color="white" color="blue103"
                    density="compact" variant="outlined"
                    item-title="mnlCtgryNm" item-value="mnlCtgryPk"
                    :items="ctgrayInf.list"                    
                    hide-details>               
                </v-select>
            </v-col>            
            <v-col class="pl-4" cols="4">
                <v-select
                    v-model="mnlInf.mnlCtgryPk"
                    class="slt-Theme"
                    base-color="grey002" bg-color="white" color="blue103"
                    density="compact" variant="outlined"
                    item-title="mnlCtgryNm" item-value="mnlCtgryPk"
                    :items="ctgrayInf.listDtl"
                    hide-details>               
                </v-select>
            </v-col>
        </v-row>     
        <v-divider class="my-2" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <span class="gothicA1 fnt0-9 ml-1">내용</span>
        </v-row>
        <v-divider class="mt-1-qrtr-1" color="white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <CmmEdtr v-model="mnlInf.mnlCntntHtml" @plainTxt="rflEdtTxt"></CmmEdtr>
            <!-- <v-textarea
                v-model="mnlInf.cntnt"
                class="txtAreaDft" color="#90CAF9"
                variant="filled" rows="13"
                no-resize hide-details>
            </v-textarea> -->
        </v-row>        
    </v-form>
</template>

<script setup>
    import { reactive, onMounted, watch, ref, } from 'vue'
    import { glbDlg, glbRules, notiInf, lgnInf } from "@/state"
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    import CmmEdtr from '@/components/commons/CmmEdtr.vue'

    const props = defineProps(['psdObj'])
    const router = useRouter()
    const dlgState = glbDlg()
    const rules = glbRules()
    const noti = notiInf()
    const lgn = lgnInf()
    const mnlForm = ref(false)    

    let mnlInf = reactive({
        mnlPk: 0,
        mnlTitle: '',
        mnlCntnt: '',
        mnlCntntHtml: '',
        crtDt: '',
        crtr: '',
        upMnlCtgryPk:0,
        mnlCtgryPk:0,
    })

    let ctgrayInf = reactive({
        list:[{mnlCtgryNm:'' ,mnlCtgryPk:0, ord:0,listDtl:[{}]},],
        listDtl:[{mnlCtgryNm:'' ,mnlCtgryPk:0, ord:0,listDtl:[{}]},]
    })

    onMounted(() => {
        setMnlInf(props.psdObj)
        getMnlCtgryList()
    })

    watch(() => mnlInf.upMnlCtgryPk, () => {
        setCtgrayDtl()
    })

    watch(() => props.psdObj, (newValue) => {
        setMnlInf(newValue)
    })
    watch(() => dlgState.getSuccActBtn, (newValue) => {
        if(dlgState.getDlgTyp == '103' && newValue)
            savMnl()
    })
    watch(() => dlgState.getCnclActBtn, (newValue) => {
        if(dlgState.getDlgTyp == '103' && newValue)
            rmMnl(mnlInf.mnlPk)
    })

    async function getMnlCtgryList() {
        ctgrayInf.list.splice(0)
        ctgrayInf.list.push({mnlCtgryNm:'선택' ,mnlCtgryPk:0, ord:0,listDtl:[]})
        let obj = {}
        await cmsApi.post('mnl/selMnlCtgryList', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){                    
                    response.data.result.forEach(itm => {
                        ctgrayInf.list.push(itm)                        
                    });
                    ctgrayInf.force++

                    if(mnlInf.upMnlCtgryPk > 0) setCtgrayDtl()
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // YYS 메뉴얼 저장
    async function setMnl(obj) {
        await cmsApi.post('mnl/insMnl', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    dlgState.$patch({ isDlg: false, succActBtn: false })
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    // YYS 메뉴얼 삭제
    async function rmMnl(mnlPk) {
        let obj = {}
        obj.mnlPk = mnlPk

        await cmsApi.post('mnl/delMnl', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    dlgState.$patch({ isDlg: false, cnclActBtn: false })
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    function savMnl(){
        if(mnlForm.value){
            let obj = Object.assign({}, mnlInf)            
            setMnl(obj)
        }
        else{
            let msg = '미입력 데이터 또는 규칙에 맞지않는 데이터를 확인해주세요.'
            noti.$patch({ stt: true, ttl: 'Warning', cntnt: msg, type: 'warning', tmOut: 2500 })
            dlgState.$patch({ succActBtn: false })
        }
    }

    function setMnlInf(obj) {
        mnlInf.mnlPk = obj.mnlPk        
        mnlInf.upMnlCtgryPk = obj.upMnlCtgryPk        
        mnlInf.mnlCtgryPk = obj.mnlCtgryPk        
        mnlInf.mnlTitle = obj.mnlTitle
        mnlInf.mnlCntnt = obj.mnlCntnt
        mnlInf.mnlCntntHtml = obj.mnlCntntHtml
        mnlInf.crtDt = obj.crtDt
        mnlInf.crtr = obj.crtr                
    }

    function rflEdtTxt(txt) {
        mnlInf.mnlCntnt = txt
    }

    function setCtgrayDtl(){        
        
        let idx = ctgrayInf.list.findIndex( v => v.mnlCtgryPk == mnlInf.upMnlCtgryPk)

        if( idx > -1){

            ctgrayInf.listDtl.splice(0)
            ctgrayInf.listDtl.push({mnlCtgryNm:'선택' ,mnlCtgryPk:0, ord:0})
            
            ctgrayInf.list[idx].listDtl.forEach(itm => {
                ctgrayInf.listDtl.push(itm)
            });

            let cidx = ctgrayInf.list[idx].listDtl.findIndex( v => v.mnlCtgryPk == mnlInf.mnlCtgryPk)

            if(cidx == -1) mnlInf.mnlCtgryPk = 0

        } 
    }
</script>