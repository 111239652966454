<template>
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt0-9 ml-1">이름</span>
        </v-col>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt0-9 ml-1">직급</span>
        </v-col>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt0-9 ml-1">답변처리</span>
        </v-col>
    </v-row>
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="4">
            {{ intrdInfo.name }}
        </v-col>
        <v-col class="pl-2" cols="4">
            {{ intrdInfo.pstn }}
        </v-col>
        <v-col class="pl-2" cols="4">
            <v-icon v-if="intrdInfo.answrYn == '1'" size="20" color="blue">mdi-check-circle-outline</v-icon>
            <v-tooltip v-else location="bottom">
                <template v-slot:activator="{ props  }">
                    <v-btn        
                        v-bind="props"                                            
                        variant="plain"
                        density="compact"
                        class="mr-2"
                        icon   
                        @click="setAnswr(intrdInfo)"
                    >
                        <v-icon size="20"  color="red">mdi-minus-circle-outline</v-icon>
                    </v-btn>
                </template>
                <span>답변처리</span>
            </v-tooltip>
        </v-col>
    </v-row>
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt0-9 ml-1">시설명</span>
        </v-col>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt0-9 ml-1">전화번호</span>
        </v-col>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt0-9 ml-1">이메일</span>
        </v-col>
    </v-row>
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="4">
            {{ intrdInfo.fcltyClnm }}
        </v-col>
        <v-col class="pl-2" cols="4">
            {{ intrdInfo.fcltyTel }}
        </v-col>
        <v-col class="pl-2" cols="4">
            {{ intrdInfo.emailId }}@{{ intrdInfo.emailDmn }}
        </v-col>
    </v-row>
    <v-row class="pa-0" no-gutters>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt0-9 ml-1">급여종류</span>
        </v-col>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt0-9 ml-1">입소정원</span>
        </v-col>
        <v-col class="" cols="4">
            <span class="gothicA1 fnt0-9 ml-1">유입경로</span>
        </v-col>
    </v-row>
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <v-col class="pl-2" cols="4">
            {{ intrdInfo.bsnsCdNm }}
        </v-col>
        <v-col class="pl-2" cols="4">
            {{ intrdInfo.entCap }}
        </v-col>
        <v-col class="pl-2" cols="4">
            <span v-if="intrdInfo.histy != '9'">{{ intrdInfo.histyNm }}</span>
            <span v-else>{{ intrdInfo.histyCntnt }}</span>
        </v-col>
    </v-row>    
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row class="pa-0" no-gutters>
        <span class="gothicA1 fnt0-9 ml-1">이전에 사용하던 노인장기요양 관리프로그램이 있었나요?</span>
    </v-row>
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider>    
    <v-row class="pa-0" no-gutters>        
        <v-col class="pl-2" cols="12">
            <span v-if="intrdInfo.prePrgrmYn == '1'">예</span>            
            <span v-else>아니오</span>
        </v-col>
    </v-row>
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider>    
    <v-row v-if="intrdInfo.prePrgrmYn == '1'" class="pa-0" no-gutters>
        <span class="gothicA1 fnt0-9 ml-1">노인장기요양 관리프로그램 이름이 무엇인가요?</span>
    </v-row>
    <v-divider v-if="intrdInfo.prePrgrmYn == '1'" class="mt-1-qrtr-1" color="white"></v-divider>    
    <v-row v-if="intrdInfo.prePrgrmYn == '1'" class="pa-0" no-gutters>        
        <v-col class="pl-2" cols="12">
            {{ intrdInfo.prePrgrmNm }}
        </v-col>
    </v-row>
    <v-divider v-if="intrdInfo.prePrgrmYn == '1'" class="mt-1-qrtr-1" color="white"></v-divider> 
    <v-row v-if="intrdInfo.prePrgrmYn == '1'" class="pa-0" no-gutters>
        <span class="gothicA1 fnt0-9 ml-1">관리프로그램 교체를 원하는 이유는 무엇인가요?</span>
    </v-row>
    <v-divider v-if="intrdInfo.prePrgrmYn == '1'" class="mt-1-qrtr-1" color="white"></v-divider>    
    <v-row v-if="intrdInfo.prePrgrmYn == '1'" class="pa-0" no-gutters>        
        <v-col class="pl-2" cols="12">
            {{ intrdInfo.chgCmnts }}
        </v-col>
    </v-row>
    <v-divider v-if="intrdInfo.prePrgrmYn == '1'" class="mt-1-qrtr-1" color="white"></v-divider>
    <v-row class="pa-0" no-gutters>
        <span class="gothicA1 fnt0-9 ml-1">문의 내용</span>
    </v-row>
    <v-divider class="mt-1-qrtr-1" color="white"></v-divider>    
    <v-row class="pa-0" no-gutters>        
        <v-textarea
            v-model="intrdInfo.cntnt"
            class="vTxtArea-theme" color="blue103" base-color="grey002"
            variant="outlined" density="compact" rows="7"
            readonly
            no-resize hide-details>
        </v-textarea>
    </v-row>
</template>
<script setup>
    import { reactive, onMounted, watch,} from 'vue'
    import { glbDlg, lgnInf, notiInf, } from "@/state";
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    const router = useRouter()
    const noti = notiInf()
    const lgn = lgnInf()
    const dlgState  = glbDlg()

    const props = defineProps(['psdObj'])

    let intrdInfo = reactive({
        intrdInqryPk :0,
        crtDt       : '',
        crtr        : '',
        name        : '',
        pstn        : '',
        fcltyClnm   : '',
        emailId     : '',
        emailDmn    : '',
        fcltyTel    : '',
        entCap      : '',
        bsnsCd      : '',
        bsnsCdNm    : '',
        pryAgrYn    : '',
        histy       : '',
        histyNm     : '',
        histyCntnt  : '',
        prePrgrmYn  : '',
        prePrgrmNm  : '',
        chgCmnts    : '',
        answrYn     : '',
        cntnt       : '',
    })

    onMounted(() => {
        setErrInf(props.psdObj)
    })

    watch(() => props.psdObj, (newValue) => {
        setErrInf(newValue)
    })

    function setErrInf(obj) {
        intrdInfo.intrdInqryPk =  obj.intrdInqryPk       
        intrdInfo.crtDt       = obj.crtDt       
        intrdInfo.crtr        = obj.crtr        
        intrdInfo.name        = obj.name   
        intrdInfo.pstn        = obj.pstn         
        intrdInfo.fcltyClnm   = obj.fcltyClnm   
        intrdInfo.emailId     = obj.emailId     
        intrdInfo.emailDmn    = obj.emailDmn    
        intrdInfo.fcltyTel    = obj.fcltyTel    
        intrdInfo.entCap      = obj.entCap      
        intrdInfo.bsnsCd      = obj.bsnsCd      
        intrdInfo.bsnsCdNm    = obj.bsnsCdNm    
        intrdInfo.pryAgrYn    = obj.pryAgrYn    
        intrdInfo.histy       = obj.histy    
        intrdInfo.histyCntnt       = obj.histyCntnt       
        intrdInfo.prePrgrmYn  = obj.prePrgrmYn  
        intrdInfo.prePrgrmNm  = obj.prePrgrmNm  
        intrdInfo.chgCmnts    = obj.chgCmnts    
        intrdInfo.answrYn     = obj.answrYn     
        intrdInfo.cntnt       = obj.cntnt  
        
        switch (obj.histy ) {
            case '1':
                intrdInfo.histyNm = '포털검색(네이버, 다음, 구글 등)'
                break;
            case '2':
                intrdInfo.histyNm = '블로그(네이버, 티스토리 등)'
                break;
            case '3':
                intrdInfo.histyNm = '뉴스 기사'
                break;
            case '4':
                intrdInfo.histyNm = '지인추천'
                break;
            case '9':
                intrdInfo.histyNm = '기타'
                break;
            default:
                break;
        }

    }

    // 답변처리
    async function setAnswr(obj) {
        await cmsApi.post('bbs/uptIntrdInqryAns', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })   
                    dlgState.$patch({ isDlg: false, succActBtn: false })                 
                }
                else{
                    
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

   
</script>