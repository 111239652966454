<template>
    <v-form v-model="ctgryForm">
        <v-row class="pa-0" no-gutters>
            <v-col cols="12">
                <div class="mb-2">
                    <v-btn
                        class="grdt-btn-blue001 text-white"
                        width="100%" height="40" variant="elevated"
                        @click="addRow()">
                        <v-icon class="mr-1">mdi-plus-circle</v-icon>
                        <span class="fnt0-7 font-weight-black">분류 추가</span>
                    </v-btn>
                </div>
                <div>
                    <CmmClsfcDraggerble
                        v-model="ctgrayInf.list" :cat="true"
                        @rmItem="isDelete">
                    </CmmClsfcDraggerble>
                </div>
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup>
    import CmmClsfcDraggerble from '@/components/commons/CmmClsfcDraggerble.vue'

    import { reactive, onMounted, watch, ref, } from 'vue'    
    import { glbDlg,  notiInf, lgnInf } from "@/state"    
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    const router = useRouter()
    const dlgState = glbDlg()
    const noti = notiInf()
    const lgn = lgnInf()

    const ctgryForm = ref(false)   
    
    let ctgrayInf = reactive({
        force:0,
        list:[]
    })

    onMounted(() => {
        getMnlCtgryList()
    })

    watch(() => dlgState.getSuccActBtn, (newValue) => {        
        if(dlgState.getDlgTyp == '104' && newValue) savMnlCtgry()
    })

    async function getMnlCtgryList() {
        ctgrayInf.list.splice(0)

        let obj = {}
        await cmsApi.post('mnl/selMnlCtgryList', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){                    
                    response.data.result.forEach(itm => {
                        ctgrayInf.list.push(itm)                        
                    });
                    ctgrayInf.force++
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    //카테고리 저장
    async function setMnlCtgry(){
        
        await cmsApi.post('mnl/insMulitMnlCtgry', ctgrayInf)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })
                    dlgState.$patch({ isDlg: false, succActBtn: false })
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    
    }

    async function delMnlCtgry(obj){
        
        await cmsApi.post('mnl/delMnlCtgry', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })    
                    getMnlCtgryList()                    
                }
                else{
                    dlgState.$patch({ succActBtn: false })
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
        
    }

    function addRow(){
        ctgrayInf.list.push({ mnlCtgryNm: '', mnlCtgryPk: 0, listDtl: [] })
    }

    function isDelete(obj, posn, idx) {
        console.log('-------- emit --------')
        console.log(obj + ', ' + posn + ', ' + idx)

        if(posn == 'depth1') {
            if(obj.listDtl.length > 0){
                noti.$patch({ stt: true, ttl: 'Error', cntnt: '자식 분류가 존재합니다.', type: 'error', tmOut: 3000 })
            } else {      
                if(obj.mnlCtgryPk > 0) 
                    delMnlCtgry(obj)
                else 
                noti.$patch({ stt: true, ttl: 'Error', cntnt: '삭제 대상을 찾을 수 없습니다.', type: 'error', tmOut: 3000 })
            }
        } else if(posn == 'depth2') {
            if(obj.mnlCtgryPk > 0) 
                delMnlCtgry(obj)
            else 
            noti.$patch({ stt: true, ttl: 'Error', cntnt: '삭제 대상을 찾을 수 없습니다.', type: 'error', tmOut: 3000 })
        }
    }

    function savMnlCtgry(){
        if(ctgryForm.value){            
            setMnlCtgry()
        }
        else{
            let msg = '미입력 데이터 또는 규칙에 맞지않는 데이터를 확인해주세요.'
            noti.$patch({ stt: true, ttl: 'Warning', cntnt: msg, type: 'warning', tmOut: 2500 })
            dlgState.$patch({ succActBtn: false })
        }
    }

</script>