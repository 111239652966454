<template>   
    <v-row no-gutters>                
        <v-col class="pa-0 ma-0" cols="2">
            <v-select
                v-model="fcltyInf.srchWd2"
                class="login-Theme"
                base-color="grey002" bg-color="white" color="blue103"
                density="compact" variant="outlined"
                item-title="valcdnm" item-value="valcd"
                :items="tblUse.vCd70"     
                @update:modelValue="getSrch"
                hide-details>               
            </v-select>
        </v-col>
        <v-col class="pl-2" cols="2">
            <v-select
                v-model="fcltyInf.jobCd"
                class="login-Theme"
                base-color="grey002" bg-color="white" color="blue103"
                density="compact" variant="outlined"
                item-title="valcdnm" item-value="valcd"
                :items="tblUse.vCd42"     
                @update:modelValue="getSrch"
                hide-details>               
            </v-select>
        </v-col>
        <v-col class="pl-2" cols="2">
            <v-text-field
                v-model="fcltyInf.srchWd1"
                class="login-theme"
                base-color="grey002" color="blue103"
                density="compact" variant="outlined"
                prepend-inner-icon="mdi-magnify">
            </v-text-field>
        </v-col>
        <v-spacer></v-spacer>                    
    </v-row>
    <v-row class="pa-0" no-gutters>
        <v-col cols="12">
            <v-data-table-server
                class="tbl-theme rounded" density="compact" color="red"
                :height="dsply.height - 355"    
                v-model:items-per-page="fcltyInf.rnLmt"                
                :search="fcltyInf.srchWd1"
                :headers="tblUse.headers"
                :items-length="tblUse.totalItems"
                :items="tblUse.serverItems"
                :loading="tblUse.loading"
                item-value="name"
                @update:options="getFcltyEmpList"
                fixed-header expand-on-click>
                <template v-slot:item="{ item }">
                    <tr>
                        <td class="txtAlgnMid">{{ item.name }}</td>
                        <td class="txtAlgnMid">{{ item.id }}</td>
                        <td class="txtAlgnMid">{{ item.jobCdNm }}</td>
                        <td class="txtAlgnMid">{{ item.wrkStcdNm }}</td>
                        <td class="txtAlgnMid">{{ item.wrkClcdNm }}</td>
                        <td class="txtAlgnMid">                           
                            <v-select
                                v-model="item.cmnAuthGrpPk"
                                class="slt-Theme"
                                base-color="grey002" bg-color="white" color="blue103"
                                density="compact" variant="outlined"
                                item-title="authNm" item-value="cmnAuthGrpPk"
                                :items="tblUse.grpList"                    
                                hide-details>               
                            </v-select>
                        </td>
                        <td class="txtAlgnMid">
                            <v-btn  
                                variant="plain"
                                density="compact"
                                icon
                                @click="savAuth(item)">
                                <v-icon size="20" color="blue">mdi-content-save-edit-outline</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table-server>
        </v-col>
    </v-row>         
</template>
<script setup>
    import { reactive, onMounted, watch,} from 'vue'
    import { lgnInf, notiInf, displayInfStore,} from "@/state";
    import { cmsApi } from '@/plugins/yysCmsApi'
    import { useRouter } from 'vue-router'

    const router = useRouter()
    const props = defineProps(['psdObj'])
    const noti = notiInf()
    const lgn = lgnInf()

    let fcltyInf = reactive({
        rnStrt: 0,
        rnLmt: 10,  
        srchWd1: '',
        srchWd2: '',
        jobCd: '',
        fcltyNum: 0,
        fcltyClnm: '',
        bsnsRgst: '',
        ceo: '',
        fcltyClcd: '',
        fcltyTel: '',
        fcltyFax: '',
        mngr: '',
        mngrTel: '',
        pbilYnNm: '',
        empCnt: '',
        grpCnt: '',
    })

    let tblUse = reactive({
        loading     : true,
        vCd41       : [],
        vCd42       : [],
        vCd70       : [],
        grpList     : [],
        totalItems  : 0,
        serverItems : [],
        headers     : [
                        { title: '이름', key: 'name', sortable: false, align: 'center', width: '130' },
                        { title: '아이디', key: 'id', sortable: false, align: 'center', width: '130' },
                        { title: '직종', key: 'jobCdNm', sortable: false, align: 'center', minWidth: '200' },
                        { title: '재직현황', key: 'wrkStcdNm', sortable: false, align: 'center', minWidth: '100' },            
                        { title: '근무구분', key: 'wrkClcdNm', sortable: false, align: 'center', width: '100' },
                        { title: '프로그램권한', key: 'cmnAuthGrpPk', sortable: false, align: 'center', width: '200' },
                        { title: '처리', key: 'actions', sortable: false, align: 'center', width: '100' },
                    ],
    })

    let dsply = reactive({
        width: 0,
        height: 0,
    })

    onMounted(() => {        
    })

    watch(() => displayInfStore().height, (newValue) => {
        dsply.height = newValue
        console.log(dsply.height - 255)
    })

    function setFcltyInf(obj){
        fcltyInf.fcltyNum   = obj.fcltyNum
        fcltyInf.fcltyClnm  = obj.fcltyClnm
        fcltyInf.bsnsRgst   = obj.bsnsRgst
        fcltyInf.ceo        = obj.ceo
        fcltyInf.fcltyClcd  = obj.fcltyClcd
        fcltyInf.fcltyTel   = obj.fcltyTel
        fcltyInf.fcltyFax   = obj.fcltyFax
        fcltyInf.mngr       = obj.mngr
        fcltyInf.mngrTel    = obj.mngrTel
        fcltyInf.pbilYnNm   = obj.pbilYnNm
        fcltyInf.empCnt     = obj.empCnt
        fcltyInf.grpCnt     = obj.grpCnt
    }

    function getSrch(){
        let obj = {}
        obj.page = 1
        obj.itemsPerPage = fcltyInf.rnLmt
        getFcltyEmpList(obj)
    }


    async function getFcltyEmpList(obj) {
        if(fcltyInf.fcltyNum == 0) setFcltyInf(props.psdObj)

        tblUse.serverItems.splice(0)
        tblUse.vCd41.splice(0)
        tblUse.vCd42.splice(0)
        tblUse.vCd42.push({valcd:'', valcdnm:'전체',})
        tblUse.vCd70.splice(0)
        tblUse.vCd70.push({valcd:'', valcdnm:'전체',})
        tblUse.grpList.splice(0)

        tblUse.loading = true
        fcltyInf.rnStrt = ((obj.page-1) * obj.itemsPerPage)

        await cmsApi.post('emp/selEmpList', fcltyInf)
            .then((response) => {
                if(response.data.statusCode == '200'){   
                    tblUse.loading = false 
                    tblUse.totalItems = response.data.result.rnTot

                    if(response.data.result.codes != undefined && response.data.result.codes != null){
                        response.data.result.codes.vCd41.forEach(el => {
                            tblUse.vCd41.push(el)
                        });
        
                        response.data.result.codes.vCd42.forEach(el => {
                            tblUse.vCd42.push(el)
                        });
        
                        response.data.result.codes.vCd70.forEach(el => {
                            tblUse.vCd70.push(el)
                        });
                    }

                    if(response.data.result.grpList != undefined && response.data.result.grpList != null){
                        response.data.result.grpList.forEach(el => {
                            tblUse.grpList.push(el)
                        });
                    }

                    response.data.result.empList.forEach((el,i) => {
                    
                        tblUse.vCd41.forEach(e=>{
                            if(e.valcd == el.wrkClcd){
                                el.wrkClcdNm = e.valcdnm
                            }
                        })

                        tblUse.vCd42.forEach(e=>{
                            if(e.valcd == el.jobCd){
                                el.jobCdNm = e.valcdnm
                            }
                        })

                        tblUse.vCd70.forEach(e=>{
                            if(e.valcd == el.wrkStcd){
                                el.wrkStcdNm = e.valcdnm
                            }
                        })

                        el.dbGrpPk = el.cmnAuthGrpPk
                        el.key = i
                        tblUse.serverItems.push(el)
                    })
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

    function savAuth(params) {
        let obj = {
            fcltyNum        : params.fcltyNum,
            empPk           : params.empPk,
            cmnAuthGrpPk    : params.cmnAuthGrpPk,
        }

        setInsCmnAcntAuthGrpMap(obj)
    }


    async function setInsCmnAcntAuthGrpMap(obj) {

        await cmsApi.post('cmm/insCmnAcntAuthGrpMap', obj)
            .then((response) => {
                if(response.data.statusCode == '200'){   
                    noti.$patch({ stt: true, ttl: 'Info', cntnt: response.data.message, type: 'info', tmOut: 2500 })                    
                }
                else{
                    if(response.data.statusCode == '610'){
                        lgn.$patch({
                            account: '',
                            name: '',
                            level: '',
                            token: '',
                            key: '',
                        })
                        noti.$patch({ stt: true, ttl: 'Warning', cntnt: response.data.message, type: 'warning', tmOut: 2500 })
    
                        router.push({ name: 'LoginComp' })
                    }
                    else
                        noti.$patch({ stt: true, ttl: 'Error', cntnt: response.data.message, type: 'error', tmOut: 3000 })
                }
            })
            .catch((error) => noti.$patch({ stt: true, ttl: 'Error', cntnt: error.message, type: 'error', tmOut: 5000 }))
    }

</script>